import React, { useCallback, useEffect, useMemo, useState } from "react";
import PageContainer from "../../components/Common/PageContainer";
import ControlPanel from "../../components/Common/ControlPanel";
import { showOptions } from "../Utility/Options";
import CustomTableContainer from "../../components/Common/CustomTableContainer";
import { useNavigate } from "react-router-dom";
import useStore from "../../zustandStore";
import useAppTypesStore from "../../zustandStore/appTypesStore";
import { ClickableCell, DisplayCell, ImageCell } from "../Utility/CustomCells";
import { format } from "date-fns";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import * as Api from "@metamorp/api-back";

const BREADCRUMB_ITEMS = [
  { title: "App Type Management", link: "#" },
  { title: "List", link: "#" },
];

const AppType = () => {
  const { token } = useStore();
  const { appTypes, setAppTypes, loading, setLoading, setError } =
    useAppTypesStore();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const [customPageSize, setCustomPageSize] = useState(
    parseInt(queryParams.pageSize) || 10,
  );
  const [currentPage, setCurrentPage] = useState(
    parseInt(queryParams.page) || 0,
  );
  const [totalPage, setTotalPage] = useState(0);
  const [totalApps, setTotalApps] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(queryParams.keyword || "");
  const [dateRange, setDateRange] = useState([
    queryParams.startDt || "",
    queryParams.endDt || "",
  ]);
  const [selectedShow, setSelectedShow] = useState(
    queryParams.show || showOptions[0].value,
  );
  const [clearTrigger, setClearTrigger] = useState(false);

  const handleRowClick = useCallback(
    (item) => {
      navigate(`/app-type/${item.applicationsIdx}${window.location.search}`);
    },
    [navigate],
  );

  const handleAddAppType = () => {
    navigate(`/app-type/new${window.location.search}`);
  };

  useEffect(() => {
    const params = {
      page: currentPage,
      pageSize: customPageSize,
      keyword: searchKeyword,
      startDt: dateRange[0],
      endDt: dateRange[1],
      show: selectedShow,
    };
    navigate(`${location.pathname}?${queryString.stringify(params)}`);
  }, [
    currentPage,
    customPageSize,
    dateRange,
    searchKeyword,
    selectedShow,
    location.pathname,
    navigate,
  ]);

  const fetchAppTypes = async () => {
    setLoading(true);
    setError(null);

    try {
      if (token) {
        Api.environment.setAccessToken(token);
      }
      const requestData = {
        pageSize: customPageSize,
        nowPage: currentPage + 1,
        pageGroup: 10,
        keyword: searchKeyword,
        displayFlag: selectedShow,
        startDt: dateRange[0] ? format(dateRange[0], "yyyy-MM-dd") : "",
        endDt: dateRange[1] ? format(dateRange[1], "yyyy-MM-dd") : "",
      };

      const response = await Api.Application.getList(requestData);
      if (response.code !== 0) {
        throw new Error(response.message);
      }
      setTotalPage(response.paging.totalPage);
      setTotalApps(response.paging.totalRecord);
      setAppTypes(response.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAppTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    token,
    currentPage,
    customPageSize,
    selectedShow,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dateRange[1],
    clearTrigger,
  ]);

  const handleSearch = () => {
    setCurrentPage(0);
    fetchAppTypes();
  };

  const handleShowSearch = (newShow) => {
    setSelectedShow(newShow);
    setCurrentPage(0);
  };

  const handleDateSearch = (newDateRange) => {
    setDateRange(newDateRange);
    setCurrentPage(0);
  };

  const clearFilters = () => {
    setSearchKeyword("");
    setDateRange("");
    setSelectedShow(showOptions[0].value);
    setCurrentPage(0);
    setClearTrigger((prev) => !prev);
  };

  const columns = useMemo(
    () => [
      {
        Header: "No",
        accessor: "applicationsIdx",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "이미지",
        accessor: "imgPath",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }) => <ImageCell value={value} />,
      },
      {
        Header: "타입명",
        accessor: "name",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value }, row }) => (
          <ClickableCell
            value={value}
            row={row.original}
            handleRowClick={handleRowClick}
          />
        ),
      },
      {
        Header: "표시여부",
        accessor: "displayFlag",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }) => <DisplayCell value={value} />,
      },
      {
        Header: "등록자",
        accessor: "regName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "등록일자",
        accessor: "regDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정자",
        accessor: "updName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정일자",
        accessor: "updDt",
        disableFilters: true,
        filterable: false,
      },
    ],
    [handleRowClick],
  );

  return (
    <PageContainer
      breadcrumbItems={BREADCRUMB_ITEMS}
      title="App Type Management"
    >
      <ControlPanel
        placeholder="어플리케이션 타입명, 등록자, 수정자명으로 검색해주세요."
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
        clearFilters={clearFilters}
        onSearch={handleSearch}
        dateRange={dateRange}
        setDateRange={handleDateSearch}
        options2={showOptions}
        selectedOption2={selectedShow}
        setSelectedOption2={handleShowSearch}
        selectTitle2="표시여부"
        isSingleSelect={true}
      />
      <CustomTableContainer
        btnTitle="타입 등록"
        sortByIdx="applicationsIdx"
        handleAddItem={handleAddAppType}
        handleRowClick={handleRowClick}
        columns={columns || []}
        data={appTypes || []}
        isPagination={true}
        customPageSize={customPageSize}
        totalPage={totalPage}
        totalRecord={totalApps}
        setCustomPageSize={setCustomPageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isLoading={loading}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
      />
    </PageContainer>
  );
};

export default AppType;
