import { create } from "zustand";
import * as Api from "@metamorp/api-back";

const useSupportProfileStore = create((set) => ({
  supports: [],
  loading: false,
  error: null,
  applications: [],
  setSupports: (supports) => set({ supports }),
  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error }),
  setApplications: (applications) => set({ applications }),
  getApplicationsTypes: async () => {
    try {
      const response = await Api.Common.getApplicationList();

      set({ applications: response.data });
    } catch (error) {
      console.error("Error fetching applications:", error);
    }
  },
}));

export default useSupportProfileStore;
