import { Button, Table } from "reactstrap";
import * as Api from "@metamorp/api-back";
import { useNavigate } from "react-router-dom";

const ResultTable = ({ data }: { data: Api.Response.SlicingResultItem[] }) => {
  const navigate = useNavigate();
  const redirectToSlicing = (slicingIdx: number | null) => {
    return () => {
      navigate(`/slicing-request?keyword=${slicingIdx}`);
    };
  };

  return (
    <div className="table-responsive">
      <Table>
        <thead>
          <tr>
            <th>장비번호</th>
            <th>슬라이싱번호</th>
            <th>실패메시지</th>
            <th>바로가기</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item) => (
            <tr key={item.appIdx}>
              <td>{item.appIdx}</td>
              <td>{item.slicingIdx ?? "-"}</td>
              <td>{item.failMsg ?? "-"}</td>
              <td>
                {item.state ? (
                  <Button
                    type="button"
                    color="primary"
                    className="me-2"
                    onClick={redirectToSlicing(item.slicingIdx)}
                  >
                    Click me
                  </Button>
                ) : (
                  "-"
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ResultTable;
