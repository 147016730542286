import { useRef } from "react";
import { CardBody, CardBodyProps } from "reactstrap";

type ContainerProps = {
  children: React.ReactNode;
  onFilesDropped: (files: FileList) => void;
} & CardBodyProps;

const CustomCardBody = ({
  children,
  onFilesDropped,
  ...props
}: ContainerProps) => {
  const editorsRef = useRef<NodeListOf<HTMLDivElement> | null>(null);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!editorsRef.current) {
      editorsRef.current = document.querySelectorAll(
        ".ck.ck-reset.ck-editor.ck-rounded-corners",
      );
    }
    editorsRef.current.forEach((editor) => {
      editor.style.pointerEvents = "none";
    });
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    editorsRef.current?.forEach((editor) => {
      editor.style.pointerEvents = "";
    });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      onFilesDropped(e.dataTransfer.files);
      e.dataTransfer.clearData();
    }
  };

  const handleDragLeave = () => {
    editorsRef.current?.forEach((editor) => {
      editor.style.pointerEvents = "";
    });
  };

  return (
    <CardBody
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragLeave={handleDragLeave}
      {...props}
    >
      {children}
    </CardBody>
  );
};

export default CustomCardBody;
