import { FormGroup as ReactFormGroup, Input, InputGroupText } from "reactstrap";

import styled from "styled-components";

export const FormGroup = styled(ReactFormGroup)`
  margin: 0 !important;
`;

export const SearchInput = styled(Input)`
  height: 30px;
`;

export const CustomInputGroupText = styled(InputGroupText)`
  height: 30px;
`;

export const CustomSelectContainer = styled.div`
  position: relative;

  select:hover {
    cursor: pointer;
  }
`;

export const CustomSelect = styled.select`
  height: 30px;
  cursor: pointer;
  padding: 5px;
  padding-left: 10px;
`;

export const ArrowDownIcon = styled.i`
  font-size: 20px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
`;
