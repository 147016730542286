import React, { useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";

import * as Api from "@metamorp/api-back";

import CustomTableContainer from "../../components/Common/CustomTableContainer";
import ControlPanel from "../../components/Common/ControlPanel";
import PageContainer from "../../components/Common/PageContainer";
import useUserStore from "../../zustandStore/userStore";

import "../../components/Common/customStyles.css";

import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";

import useStore from "../../zustandStore";
import { ClickableCell, ImageCell } from "../Utility/CustomCells";

export const memberStatusOptions = [
  { label: "전체", value: -1 },
  { label: "정상", value: 0 },
  { label: "탈퇴", value: 1 },
  { label: "정지", value: 2 },
];

const registrationTypeOptions = [
  { label: "전체", value: -1 },
  { label: "자체가입", value: 0 },
  { label: "관리자등록", value: 1 },
];

const DateCell = ({ value }) => (
  <span>{format(new Date(value), "yyyy-MM-dd")}</span>
);

const StatusCell = ({ value }) => {
  let statusColor = "";
  switch (value) {
    case 0: // 정상
      statusColor = "#1cbb8c";
      break;
    case 1: // 탈퇴
      statusColor = "#ff3d5f";
      break;
    case 2: // 정지
      statusColor = "#75788d";
      break;
    default:
      statusColor = "black";
      break;
  }

  return (
    <span style={{ color: statusColor }}>
      {memberStatusOptions.find((option) => option.value === value)?.label}
    </span>
  );
};

const UserRegTypeCell = ({ value }) => {
  let regTypeColor = "";
  switch (value) {
    case 0: // 자체가입
      regTypeColor = "black";
      break;
    case 1: // 관리자등록
      regTypeColor = "#5664d2";
      break;
    default:
      regTypeColor = "black";
      break;
  }

  return (
    <span style={{ color: regTypeColor }}>
      {registrationTypeOptions.find((option) => option.value === value)?.label}
    </span>
  );
};

const BREADCRUMB_ITEMS = [
  { title: "Members", link: "#" },
  { title: "List", link: "#" },
];

const Users = () => {
  const { token } = useStore();
  const { users, setUsers, loading, setLoading, setError } = useUserStore();
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = queryString.parse(location.search);

  const [customPageSize, setCustomPageSize] = useState(
    parseInt(queryParams.pageSize) || 10,
  );
  const [currentPage, setCurrentPage] = useState(
    parseInt(queryParams.page) || 0,
  );
  const [totalPage, setTotalPage] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(queryParams.keyword || "");
  const [dateRange, setDateRange] = useState([
    queryParams.startDt || "",
    queryParams.endDt || "",
  ]);
  const [selectedStatus, setSelectedStatus] = useState(
    queryParams.status || memberStatusOptions[0].value,
  );
  const [selectedRegType, setSelectedRegType] = useState(
    queryParams.regType || registrationTypeOptions[0].value,
  );
  const [clearTrigger, setClearTrigger] = useState(false);

  const handleRowClick = useCallback(
    (item) => {
      navigate(`/users/${item.userIdx}${window.location.search}`);
    },
    [navigate],
  );

  const handleAddUser = () => {
    navigate(`/users/new${window.location.search}`);
  };

  useEffect(() => {
    const params = {
      page: currentPage,
      pageSize: customPageSize,
      keyword: searchKeyword,
      startDt: dateRange[0],
      endDt: dateRange[1],
      status: selectedStatus,
      regType: selectedRegType,
    };
    navigate(`${location.pathname}?${queryString.stringify(params)}`);
  }, [
    currentPage,
    customPageSize,
    searchKeyword,
    dateRange,
    selectedStatus,
    selectedRegType,
    navigate,
    location.pathname,
  ]);

  const fetchUsers = async () => {
    setLoading(true);
    setError(null);

    try {
      if (token) {
        Api.environment.setAccessToken(token);
      }
      const requestData = {
        pageSize: customPageSize,
        nowPage: currentPage + 1,
        pageGroup: 10,
        keyword: searchKeyword,
        stateFlag: selectedStatus,
        regFlag: selectedRegType,
        startDt: dateRange[0] ? format(dateRange[0], "yyyy-MM-dd") : "",
        endDt: dateRange[1] ? format(dateRange[1], "yyyy-MM-dd") : "",
      };

      const response = await Api.User.getList(requestData);
      if (response.code !== 0) {
        throw new Error(response.message);
      }

      setTotalPage(response.paging.totalPage);
      setTotalUsers(response.paging.totalRecord);
      setUsers(response.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    token,
    currentPage,
    customPageSize,
    selectedRegType,
    selectedStatus,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dateRange[1],
    clearTrigger,
  ]);

  const handleSearch = () => {
    setCurrentPage(0);
    fetchUsers();
  };

  const handleStatusSearch = (newStatus) => {
    setSelectedStatus(newStatus);
    setCurrentPage(0);
  };

  const handleRegTypeSearch = (newRegType) => {
    setSelectedRegType(newRegType);
    setCurrentPage(0);
  };

  const handleDateSearch = (newDateRange) => {
    setDateRange(newDateRange);
    setCurrentPage(0);
  };

  const clearFilters = async () => {
    setSearchKeyword("");
    setDateRange("");
    setSelectedStatus(memberStatusOptions[0].value);
    setSelectedRegType(registrationTypeOptions[0].value);
    setCurrentPage(0);
    setClearTrigger((prev) => !prev);
  };

  const columns = useMemo(
    () => [
      {
        Header: "No",
        accessor: "userIdx",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "프로필",
        accessor: "imgPath",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }) => (
          <ImageCell value={value} isUser="true" />
        ),
      },
      {
        Header: "ID",
        accessor: "userId",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value }, row }) => (
          <ClickableCell
            value={value}
            row={row.original}
            handleRowClick={handleRowClick}
          />
        ),
      },
      {
        Header: "닉네임",
        accessor: "nickName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "직업",
        accessor: "job",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "전화번호",
        accessor: "phone",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "회원상태",
        accessor: "stateFlag",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }) => <StatusCell value={value} />,
      },
      {
        Header: "가입일자",
        accessor: "regDt",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }) => <DateCell value={value} />,
      },
      {
        Header: "등록구분",
        accessor: "regFlag",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }) => <UserRegTypeCell value={value} />,
      },
      {
        Header: "등록자",
        accessor: "adminName",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }) => {
          return value ? value : "-";
        },
      },
    ],
    [handleRowClick],
  );

  return (
    <React.Fragment>
      <PageContainer title="Members" breadcrumbItems={BREADCRUMB_ITEMS}>
        <ControlPanel
          placeholder="이름, ID, 전화번호로 검색해주세요."
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          clearFilters={clearFilters}
          onSearch={handleSearch}
          dateRange={dateRange}
          setDateRange={handleDateSearch}
          options1={memberStatusOptions}
          options2={registrationTypeOptions}
          selectedOption1={selectedStatus}
          setSelectedOption1={handleStatusSearch}
          selectedOption2={selectedRegType}
          setSelectedOption2={handleRegTypeSearch}
          selectTitle1="회원 상태"
          selectTitle2="등록 구분"
        />
        <CustomTableContainer
          btnTitle="회원 등록"
          sortByIdx="userIdx"
          handleAddItem={handleAddUser}
          handleRowClick={handleRowClick}
          isLoading={loading}
          columns={columns || []}
          data={users || []}
          isPagination={true}
          customPageSize={customPageSize}
          totalPage={totalPage}
          totalRecord={totalUsers}
          setCustomPageSize={setCustomPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          className="custom-header-css table align-middle table-nowrap"
          tableClassName="table-centered align-middle table-nowrap mb-0"
          theadClassName="text-muted table-light"
        />
      </PageContainer>
    </React.Fragment>
  );
};

export default Users;
