let requiredFields = [
  "profileName",
  //   "displayFlag",
  "bumpHeight",
  "pinDepth",
  "pinTopDiameter",
  "pinBottomDiameter",
  "pinLength",
  "pinJointDiameter",
  //   "useVerticalPin",
  "voxelSize",
  "infillDistance",
  "overhangAngle",
  "borderOffset",
  "lineDistance",
  "minimumHeight",
  //   "useBrace",
  //   "useBase",
  "columnTopDiameter",
  "columnBottomDiameter",
  "braceDiameter",
  "baseHeight",
  "baseDiameter",
  //   "useRaft",
  "raftHeight",
  "raftOffset",
  "raftHoleDistance",
  "raftHoleDiameter",
  //   "applicationsIdx",
  //   "type",
  //   "part",
  //   "size",
  //   "palatal",
  //   "bottom",
];

export const validateSupport = ({ support }) => {
  let fieldsToCheck = [...requiredFields];

  if (!support.useBase) {
    fieldsToCheck = fieldsToCheck.filter(
      (field) => field !== "baseHeight" && field !== "baseDiameter",
    );
  }

  if (!support.useBrace) {
    fieldsToCheck = fieldsToCheck.filter((field) => field !== "braceDiameter");
  }

  if (!support.useRaft) {
    fieldsToCheck = fieldsToCheck.filter(
      (field) =>
        ![
          "raftHeight",
          "raftOffset",
          "raftHoleDistance",
          "raftHoleDiameter",
        ].includes(field),
    );
  }

  const missingFields = fieldsToCheck.filter((field) => {
    const value = support[field];
    return value === undefined || value === null;
  });

  return {
    isValid: missingFields.length === 0,
    missingFields,
  };
};
