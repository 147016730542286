import React, { useEffect, useMemo, useState } from "react";
import PageContainer from "../../components/Common/PageContainer";
import ControlPanelTypes from "../../components/Common/ControlPanelTypes";
import queryString from "query-string";
import * as Api from "@metamorp/api-back";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { CellProps } from "react-table";
import { format } from "date-fns";
import { OptionProps } from "../AppEquipment";
import useStore from "../../zustandStore";
import CustomTableContainerTypes, {
  CustomColumn,
} from "../../components/Common/CustomTableContainerTypes";
import useSlicingStore from "../../zustandStore/slicingStore";

import SlicingModal from "./components";

import FilesModal from "./components/FilesModal";
import { ClickableCell, SlicingStatusCell } from "../Utility/CustomCellsType";
import useQueryParams from "../../helpers/hooks";

export type SlicingStatus =
  | "all"
  | "receipt"
  | "progress"
  | "complete"
  | "fail";

export const slicingStatusOptions: Array<{
  label: string;
  value: SlicingStatus;
}> = [
  { label: "전체", value: "all" },
  { label: "접수", value: "receipt" },
  { label: "처리중", value: "progress" },
  { label: "완료", value: "complete" },
  { label: "실패", value: "fail" },
];

const BREADCRUMB_ITEMS = [
  { title: "Slicing Request", link: "#" },
  { title: "List", link: "#" },
];

const SlicingRequest = () => {
  const { token } = useStore();
  const location = useLocation();
  const { slicingRequests, setSlicingRequests, loading, setLoading, setError } =
    useSlicingStore();
  const queryParams: queryString.ParsedQuery<string> = queryString.parse(
    location.search,
  );
  const { pageSize, page, keyword, dateRange, parseQueryParam } =
    useQueryParams();
  const [customPageSize, setCustomPageSize] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(page);
  const [totalPage, setTotalPage] = useState(0);
  const [totalSlicing, setTotalSlicing] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(keyword);
  const [selectedDates, setSelectedDates] =
    useState<[string, string]>(dateRange);
  const [selectedStatus, setSelectedStatus] = useState<SlicingStatus>(
    (queryParams.status as SlicingStatus) ?? slicingStatusOptions[0].value,
  );
  const [clients, setClients] = useState<OptionProps[]>([]);
  const [selectedClient, setSelectedClient] = useState<number>(
    parseQueryParam(queryParams.client) ?? -1,
  );
  const [clearTrigger, setClearTrigger] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFailMessage, setSelectedFailMessage] = useState("");
  const [selectedSlicing, setSelectedSlicing] =
    useState<Api.Response.SlicingItem | null>(null);
  const [isFilesModalOpen, setIsFilesModalOpen] = useState(false);

  const getClients = async () => {
    const response = await Api.Common.getClientList();
    const clients = response?.data;
    const updatedClients = [
      {
        clientName: "전체",
        clientIdx: -1,
      },
      ...clients,
    ];
    const options = updatedClients.map((client) => ({
      label: client.clientName,
      value: client.clientIdx,
    }));
    setClients(options);
  };

  useEffect(() => {
    if (token) {
      Api.environment.setAccessToken(token);
      getClients();
    }
  }, [token]);

  const fetchSlicing = async () => {
    setLoading(true);
    setError(null);

    try {
      if (token) {
        Api.environment.setAccessToken(token);
      }

      const requestData = {
        pageSize: customPageSize,
        nowPage: currentPage + 1,
        pageGroup: 10,
        keyword: typeof searchKeyword === "string" ? searchKeyword : "",
        clientIdx: selectedClient,
        status: selectedStatus === "all" ? undefined : selectedStatus,
        startDt: selectedDates[0] ? format(selectedDates[0], "yyyy-MM-dd") : "",
        endDt: selectedDates[1] ? format(selectedDates[1], "yyyy-MM-dd") : "",
      };

      const response = await Api.Slicing.getList(requestData);
      setTotalPage(response.paging.totalPage);
      setTotalSlicing(response.paging.totalRecord);
      setSlicingRequests(response.data);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
        console.error("Error fetching slicing requests", error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSlicing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    token,
    currentPage,
    customPageSize,
    selectedClient,
    selectedStatus,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    selectedDates[1],
    clearTrigger,
  ]);

  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    setSearchKeyword(queryParams.keyword || "");
  }, [location.search]);

  const handleSearch = () => {
    setCurrentPage(0);
    fetchSlicing();
  };

  const handleStatusSearch = (newStatus: SlicingStatus) => {
    setSelectedStatus(newStatus);
    setCurrentPage(0);
  };

  const handleDateSearch = (newDateRange: [string, string]) => {
    setSelectedDates(newDateRange);
    setCurrentPage(0);
  };

  const handleClientSearch = (newClient: number) => {
    setSelectedClient(newClient);
    setCurrentPage(0);
  };

  const clearFilters = () => {
    setSearchKeyword("");
    setSelectedDates(["", ""]);
    setSelectedStatus(slicingStatusOptions[0].value);
    setSelectedClient(-1);
    setCurrentPage(0);
    setClearTrigger((prev) => !prev);
  };

  const handleDownloadResFile = (item: Api.Response.SlicingItem) => {
    const fileUrl = item.resFileUrl;
    if (fileUrl) {
      window.location.href = fileUrl;
    } else {
      console.error("Response file URL is missing.");
    }
  };

  const handleIsModalOpen = (value: string) => {
    setSelectedFailMessage(value);
    setIsModalOpen(true);
  };

  const handleIsFilesModalOpen = (item: Api.Response.SlicingItem) => {
    setSelectedSlicing(item);
    setIsFilesModalOpen(true);
  };

  const columns: CustomColumn<Api.Response.SlicingItem>[] = useMemo(
    () => [
      {
        Header: "No",
        accessor: "slicingIdx",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "요청고객사",
        accessor: "clientName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "대상명(APP)",
        accessor: "appName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "프린터명",
        accessor: "printerName",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.SlicingItem>) => (
          <span>{value ?? "-"}</span>
        ),
      },
      {
        Header: "프린터ID",
        accessor: "printerIdx",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.SlicingItem>) => (
          <span>{value ?? "-"}</span>
        ),
      },
      {
        Header: "처리상태",
        accessor: "status",
        disableFilters: true,
        filterable: false,
        Cell: ({
          cell: { value },
        }: CellProps<Api.Response.SlicingItem, SlicingStatus>) => (
          <SlicingStatusCell value={value} />
        ),
      },
      {
        Header: "완료보고",
        accessor: "isClientReport",
        disableFilters: true,
        filterable: false,
        Cell: ({
          cell: { value },
        }: CellProps<Api.Response.SlicingItem, boolean>) => {
          const style: React.CSSProperties | undefined = value
            ? { fontWeight: "bold" }
            : undefined;
          return <span style={style}>{value ? "보고함" : "-"}</span>;
        },
      },
      {
        Header: "처리요청",
        accessor: "isAppRequest",
        disableFilters: true,
        filterable: false,
        Cell: ({
          cell: { value },
        }: CellProps<Api.Response.SlicingItem, boolean>) => {
          const style: React.CSSProperties | undefined = value
            ? { fontWeight: "bold" }
            : undefined;
          return <span style={style}>{value ? "요청함" : "-"}</span>;
        },
      },
      {
        Header: "실패사유",
        accessor: "failMessage",
        disableFilters: true,
        filterable: false,
        Cell: ({
          cell: { value },
        }: CellProps<Api.Response.SlicingItem, string | undefined>) => {
          const hasValue = !!value && value.length > 0;
          const style: React.CSSProperties | undefined = hasValue
            ? {
                textDecoration: "underline",
                cursor: "pointer",
              }
            : undefined;
          return (
            <span
              style={style}
              onClick={
                hasValue ? () => handleIsModalOpen(value || "") : undefined
              }
            >
              {hasValue ? "보기" : "-"}
            </span>
          );
        },
      },
      {
        Header: "요청파일",
        accessor: "reqFileList",
        disableFilters: true,
        filterable: false,
        Cell: ({ row }: { row: { original: Api.Response.SlicingItem } }) => (
          <ClickableCell<Api.Response.SlicingItem>
            value={
              <span>
                다운로드
                <i className="ri-download-line" />
              </span>
            }
            row={row.original}
            handleRowClick={handleIsFilesModalOpen}
          />
        ),
      },
      {
        Header: "결과파일",
        accessor: "resFileUrl",
        disableFilters: true,
        filterable: false,
        Cell: ({
          row,
        }: CellProps<Api.Response.SlicingItem, string | undefined>) =>
          row.original.resFileUrl ? (
            <ClickableCell
              value={
                <span>
                  다운로드
                  <i className="ri-download-line" />
                </span>
              }
              row={row.original}
              handleRowClick={handleDownloadResFile}
            />
          ) : (
            <span>-</span>
          ),
      },
      {
        Header: "등록일자",
        accessor: "regDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "갱신일자",
        accessor: "updDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "만료일자",
        accessor: "expDt",
        disableFilters: true,
        filterable: false,
      },
    ],
    [],
  );

  return (
    <React.Fragment>
      <PageContainer breadcrumbItems={BREADCRUMB_ITEMS} title="Slicing Request">
        <ControlPanelTypes
          placeholder="고객사명, 대상명, 등록자,수정자명으로 검색해주세요."
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          clearFilters={clearFilters}
          onSearch={handleSearch}
          dateRange={selectedDates}
          setDateRange={handleDateSearch}
          options1={clients}
          option1Type="number"
          selectedOption1={selectedClient}
          setSelectedOption1={handleClientSearch}
          options2={slicingStatusOptions}
          option2Type="string"
          selectedOption2={selectedStatus}
          setSelectedOption2={handleStatusSearch}
          selectTitle1="고객사"
          selectTitle2="처리상태"
        />
        <CustomTableContainerTypes
          sortByIdx="slicingIdx"
          columns={columns || []}
          data={slicingRequests || []}
          customPageSize={customPageSize}
          totalPage={totalPage}
          totalRecord={totalSlicing}
          setCustomPageSize={setCustomPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isLoading={loading}
          className="custom-header-css table align-middle table-nowrap"
          tableClassName="table-centered align-middle table-nowrap mb-0"
          theadClassName="text-muted table-light"
        />
        <SlicingModal
          isLoading={loading}
          title="실패사유"
          data={selectedFailMessage}
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
        />
        <FilesModal
          isLoading={loading}
          title="다운로드"
          data={selectedSlicing}
          isOpen={isFilesModalOpen}
          setIsOpen={setIsFilesModalOpen}
        />

        <ToastContainer />
      </PageContainer>
    </React.Fragment>
  );
};

export default SlicingRequest;
