import { Card, CardBody, Col, InputGroup, Label, Row } from "reactstrap";
import Flatpickr from "react-flatpickr";

import "./customStyles.css";
import {
  ArrowDownIcon,
  CustomInputGroupText,
  CustomSelect,
  CustomSelectContainer,
  SearchInput,
} from "../../pages/Users/styled";
import { useRef, useState } from "react";
import { CustomTooltip } from "./styled";

const ControlPanel = ({
  children,
  placeholder,
  searchKeyword,
  setSearchKeyword,
  clearFilters,
  onSearch,
  dateRange,
  setDateRange,
  options0,
  selectedOption0,
  setSelectedOption0,
  selectTitle0,
  options1,
  options2,
  selectedOption1,
  selectedOption2,
  setSelectedOption1,
  setSelectedOption2,
  selectTitle1,
  selectTitle2,
  isSingleSelect,
}) => {
  const flatpickrRef = useRef(null);
  const [isHovered, setIsHovered] = useState({
    filter: false,
    search: false,
    calendar: false,
  });

  const handleIconClick = () => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.open();
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onSearch();
    }
  };

  return (
    <Col xs={12} style={{ padding: "0 6px" }}>
      <Card>
        <CardBody>
          <Row>
            <Col sm={12} className="form-control-sm">
              <InputGroup>
                <SearchInput
                  value={searchKeyword}
                  type="text"
                  id="example-text-input"
                  placeholder={placeholder}
                  onChange={(e) => setSearchKeyword(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
                <CustomInputGroupText
                  onClick={clearFilters}
                  className="custom-color cursor-pointer"
                  id="FilterTooltip"
                >
                  <i className="ri-refresh-line"></i>
                </CustomInputGroupText>
                <CustomTooltip
                  placement="top"
                  isOpen={isHovered.filter}
                  target="FilterTooltip"
                  toggle={() =>
                    setIsHovered((prev) => ({ ...prev, filter: !prev.filter }))
                  }
                >
                  필터 초기화
                </CustomTooltip>
                <CustomInputGroupText
                  className="custom-color cursor-pointer"
                  onClick={onSearch}
                  id="SearchTooltip"
                >
                  <i className="ri-search-line"></i>
                </CustomInputGroupText>
                <CustomTooltip
                  placement="top"
                  isOpen={isHovered.search}
                  target="SearchTooltip"
                  toggle={() =>
                    setIsHovered((prev) => ({ ...prev, search: !prev.search }))
                  }
                >
                  검색
                </CustomTooltip>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={12} className="form-control-sm">
              <InputGroup className="cursor-pointer">
                <Flatpickr
                  ref={flatpickrRef}
                  className="form-control d-block form-control-sm"
                  placeholder="YYYY-MM-DD ~ YYYY-MM-DD"
                  options={{
                    mode: "range",
                    dateFormat: "Y-m-d",
                  }}
                  value={dateRange}
                  onChange={(selectedDates) => setDateRange(selectedDates)}
                />
                <CustomInputGroupText
                  className="custom-color"
                  onClick={handleIconClick}
                  id="CalendarTooltip"
                >
                  <i className="ri-calendar-event-fill cursor-pointer"></i>
                </CustomInputGroupText>
                <CustomTooltip
                  placement="top"
                  isOpen={isHovered.calendar}
                  target="CalendarTooltip"
                  toggle={() =>
                    setIsHovered((prev) => ({
                      ...prev,
                      calendar: !prev.calendar,
                    }))
                  }
                >
                  기간 선택
                </CustomTooltip>
              </InputGroup>
            </Col>
            {options0 ? (
              <>
                <Col sm={4} className="form-select-sm">
                  <div>
                    <Label className="form-label">{selectTitle0}</Label>
                    <CustomSelectContainer>
                      <CustomSelect
                        className="form-control"
                        value={selectedOption0}
                        onChange={(e) => {
                          const selectedValue = parseInt(e.target.value, 10);
                          setSelectedOption0(selectedValue);
                        }}
                      >
                        {options0.map((option) => (
                          <option
                            key={option.applicationsIdx}
                            value={option.applicationsIdx}
                          >
                            {option.name}
                          </option>
                        ))}
                      </CustomSelect>
                      <ArrowDownIcon className="ri-arrow-down-s-line"></ArrowDownIcon>
                    </CustomSelectContainer>
                  </div>
                </Col>
                <Col sm={4} className="form-select-sm">
                  <div>
                    <Label className="form-label">{selectTitle1}</Label>
                    <CustomSelectContainer>
                      <CustomSelect
                        className="form-control"
                        value={selectedOption1}
                        onChange={(e) => setSelectedOption1(e.target.value)}
                      >
                        {options1.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </CustomSelect>
                      <ArrowDownIcon className="ri-arrow-down-s-line"></ArrowDownIcon>
                    </CustomSelectContainer>
                  </div>
                </Col>
                <Col sm={4} className="form-select-sm">
                  <div>
                    <Label className="form-label ">{selectTitle2}</Label>
                    <CustomSelectContainer>
                      <CustomSelect
                        className="form-control "
                        value={selectedOption2}
                        onChange={(e) => setSelectedOption2(e.target.value)}
                      >
                        {options2.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </CustomSelect>
                      <ArrowDownIcon className="ri-arrow-down-s-line"></ArrowDownIcon>
                    </CustomSelectContainer>
                  </div>
                </Col>
              </>
            ) : (
              <>
                {selectedOption1 && (
                  <Col sm={6} className="form-select-sm">
                    <div>
                      <Label className="form-label">{selectTitle1}</Label>
                      <CustomSelectContainer>
                        <CustomSelect
                          className="form-control"
                          value={selectedOption1}
                          onChange={(e) => setSelectedOption1(e.target.value)}
                        >
                          {options1.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </CustomSelect>
                        <ArrowDownIcon className="ri-arrow-down-s-line"></ArrowDownIcon>
                      </CustomSelectContainer>
                    </div>
                  </Col>
                )}
                <Col sm={isSingleSelect ? 12 : 6} className="form-select-sm">
                  <div>
                    <Label className="form-label ">{selectTitle2}</Label>
                    <CustomSelectContainer>
                      <CustomSelect
                        className="form-control "
                        value={selectedOption2}
                        onChange={(e) => setSelectedOption2(e.target.value)}
                      >
                        {options2.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </CustomSelect>
                      <ArrowDownIcon className="ri-arrow-down-s-line"></ArrowDownIcon>
                    </CustomSelectContainer>
                  </div>
                </Col>
              </>
            )}
          </Row>
          <Row>{children}</Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ControlPanel;
