import React, { useCallback, useEffect, useMemo, useState } from "react";
import PageContainer from "../../components/Common/PageContainer";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { CellProps } from "react-table";
import * as Api from "@metamorp/api-back";
import useStore from "../../zustandStore";
import useCustomerStore from "../../zustandStore/customerStore";
import ControlPanelTypes from "../../components/Common/ControlPanelTypes";
import { format } from "date-fns";
import CustomTableContainerTypes, {
  type CustomColumn,
} from "../../components/Common/CustomTableContainerTypes";
import useQueryParams from "../../helpers/hooks";

export const stateOptions = [
  { label: "전체", value: -1 },
  { label: "정상", value: 0 },
  { label: "정지", value: 1 },
];

type ClickableCellProps = {
  value: string;
  row: Api.Response.ClientItem;
  handleRowClick: (item: { clientIdx: number }) => void;
};

const ClickableCell = ({ value, row, handleRowClick }: ClickableCellProps) => {
  const handleClick = () => {
    if (row.clientIdx !== undefined) {
      handleRowClick({ clientIdx: row.clientIdx });
    } else {
      console.error("clientIdx is undefined");
    }
  };
  return (
    <span
      style={{ textDecoration: "underline", cursor: "pointer" }}
      onClick={handleClick}
    >
      {value}
    </span>
  );
};

const BREADCRUMB_ITEMS = [
  { title: "Customer Management", link: "#" },
  { title: "List", link: "#" },
];

const Customers = () => {
  const { token } = useStore();
  const { customers, setCustomers, loading, setLoading, setError } =
    useCustomerStore();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams: queryString.ParsedQuery<string> = queryString.parse(
    location.search,
  );
  const { pageSize, page, keyword, dateRange, parseQueryParam } =
    useQueryParams();
  const [customPageSize, setCustomPageSize] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(page);
  const [totalPage, setTotalPage] = useState(0);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(keyword);
  const [selectedDates, setSelectedDates] =
    useState<[string, string]>(dateRange);
  const [selectedStatus, setSelectedStatus] = useState<number>(
    parseQueryParam(queryParams.show) ?? stateOptions[0].value,
  );
  const [clearTrigger, setClearTrigger] = useState(false);

  const handleRowClick = useCallback(
    (item: { clientIdx: number }) => {
      navigate(`/customer/${item.clientIdx}${window.location.search}`);
    },
    [navigate],
  );

  const handleAddCustomer = () => {
    navigate(`/customer/new${window.location.search}`);
  };

  useEffect(() => {
    const params = {
      page: currentPage,
      pageSize: customPageSize,
      keyword: searchKeyword,
      startDt: selectedDates[0],
      endDt: selectedDates[1],
      show: selectedStatus,
    };
    navigate(`${location.pathname}?${queryString.stringify(params)}`);
  }, [
    currentPage,
    customPageSize,
    selectedDates,
    searchKeyword,
    selectedStatus,
    location.pathname,
    navigate,
  ]);

  const fetchCustomers = async () => {
    setLoading(true);
    setError(null);

    try {
      if (token) {
        Api.environment.setAccessToken(token);
      }
      const requestData: Api.Request.ClientList = {
        pageSize: customPageSize,
        nowPage: currentPage + 1,
        pageGroup: 10,
        keyword: typeof searchKeyword === "string" ? searchKeyword : "",
        stateFlag: selectedStatus,
        startDt: selectedDates[0] ? format(selectedDates[0], "yyyy-MM-dd") : "",
        endDt: selectedDates[1] ? format(selectedDates[1], "yyyy-MM-dd") : "",
      };

      const response = await Api.Client.getList(requestData);

      if (response.code !== 0) {
        throw new Error(response?.message.ko);
      }
      setTotalPage(response.paging.totalPage);
      setTotalCustomers(response.paging.totalRecord);
      setCustomers(response.data);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
        console.error("Error fetching customers:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    token,
    currentPage,
    customPageSize,
    selectedStatus,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    selectedDates[1],
    clearTrigger,
  ]);

  const handleSearch = () => {
    setCurrentPage(0);
    fetchCustomers();
  };

  const handleStatusSearch = (newStatus: number | string) => {
    setSelectedStatus(newStatus as number);
    setCurrentPage(0);
  };

  const handleDateSearch = (newDateRange: [string, string]) => {
    setSelectedDates(newDateRange);
    setCurrentPage(0);
  };

  const clearFilters = () => {
    setSearchKeyword("");
    setSelectedDates(["", ""]);
    setSelectedStatus(stateOptions[0].value);
    setCurrentPage(0);
    setClearTrigger((prev) => !prev);
  };

  const columns: CustomColumn<Api.Response.ClientItem>[] = useMemo(
    () => [
      {
        Header: "No",
        accessor: "clientIdx",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "고객사명",
        accessor: "clientName",
        disableFilters: true,
        filterable: false,
        Cell: ({
          cell: { value },
          row,
        }: CellProps<Api.Response.ClientItem>) => (
          <ClickableCell
            value={value as string}
            row={row.original}
            handleRowClick={handleRowClick}
          />
        ),
      },
      {
        Header: "FCM상태",
        accessor: "isFcmEnable",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.ClientItem>) => (
          <span style={{ color: value ? "#1cbb8c" : "#ff3d5f" }}>
            {value ? "등록완료" : "미등록"}
          </span>
        ),
      },
      {
        Header: "서비스상태",
        accessor: "stateFlag",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }: CellProps<Api.Response.ClientItem>) => (
          <span style={{ color: value === 0 ? "#1cbb8c" : "#ff3d5f" }}>
            {value === 0 ? "정상" : "정지"}
          </span>
        ),
      },
      {
        Header: "등록자",
        accessor: "regName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "등록일자",
        accessor: "regDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정자",
        accessor: "updName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정일자",
        accessor: "updDt",
        disableFilters: true,
        filterable: false,
      },
    ],
    [handleRowClick],
  );

  return (
    <React.Fragment>
      <PageContainer
        breadcrumbItems={BREADCRUMB_ITEMS}
        title="Customer Management"
      >
        <ControlPanelTypes
          placeholder="고객사명, 등록자, 수정자명으로 검색해주세요."
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          clearFilters={clearFilters}
          onSearch={handleSearch}
          dateRange={selectedDates}
          setDateRange={handleDateSearch}
          options2={stateOptions}
          option2Type="number"
          selectedOption2={selectedStatus}
          setSelectedOption2={handleStatusSearch}
          selectTitle2="서비스상태"
          isSingleSelect={true}
        />
        <CustomTableContainerTypes
          btnTitle="고객사 등록"
          sortByIdx="clientIdx"
          handleAddItem={handleAddCustomer}
          columns={columns || []}
          data={customers || []}
          customPageSize={customPageSize}
          totalPage={totalPage}
          totalRecord={totalCustomers}
          setCustomPageSize={setCustomPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isLoading={loading}
          className="custom-header-css table align-middle table-nowrap"
          tableClassName="table-centered align-middle table-nowrap mb-0"
          theadClassName="text-muted table-light"
        />
      </PageContainer>
    </React.Fragment>
  );
};

export default Customers;
