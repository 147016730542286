import React from "react";
import SupportProfileForm from "./SupportProfileForm";

const SupportProfileDetails = () => {
  return (
    <div>
      <SupportProfileForm isDetailView />
    </div>
  );
};

export default SupportProfileDetails;
