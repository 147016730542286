import React from "react";
import PrintingProfileForm from "./PrintingProfileForm";

const PrintingProfileDetails = () => {
  return (
    <div>
      <PrintingProfileForm isDetailView />
    </div>
  );
};

export default PrintingProfileDetails;
