import React, { useCallback, useEffect, useState } from "react";
import PageContainer from "../../components/Common/PageContainer";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import * as Api from "@metamorp/api-back";
import { ToastContainer, toast } from "react-toastify";
import ConfirmModal from "../../components/Common/Modal";
import FormButtons from "../../components/Common/FormButtons";
import FormInput from "../../components/Profile/FormInput";
import Checkbox from "../../components/Profile/Checkbox";
import { useLocation } from "react-router-dom";
import useStore from "../../zustandStore";
import { CardImgContainer } from "../Utility/styled";
import useAppTypesStore from "../../zustandStore/appTypesStore";
import CustomFileInput from "../../components/Common/CustomFileInput";
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
} from "../../helpers/constants";
import { useLocalizedMessage } from "../../helpers/hooks";

const AppTypeForm = ({ isDetailView }) => {
  const { applicationsIdx } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useStore();
  const { loading, setLoading } = useAppTypesStore();
  const getLocalizedMessage = useLocalizedMessage();

  const breadcrumbItems = [
    { title: "App Type Management", link: "#" },
    { title: `${isDetailView ? "Edit" : "Add"}`, link: "#" },
  ];
  const [app, setApp] = useState({
    name: "",
    displayFlag: 1,
    file: null,
  });
  const [fileName, setFileName] = useState(app.file?.name || "");
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState("");

  const getApplicationByIdx = useCallback(async () => {
    try {
      if (token) {
        Api.environment.setAccessToken(token);
      }
      const response = await Api.Application.get(applicationsIdx);
      const existingApp = response.data;
      if (existingApp) {
        setApp(existingApp);
      } else {
        setApp({
          name: "",
          displayFlag: 1,
          file: null,
        });
      }
    } catch (error) {
      console.error("Error fetching app from API", error);
    }
  }, [applicationsIdx, token]);

  useEffect(() => {
    if (isDetailView) {
      getApplicationByIdx();
    }
  }, [getApplicationByIdx, isDetailView]);

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    const newValue = type === "checkbox" ? checked : files ? files[0] : value;
    if (name === "file") {
      setFileName(newValue ? newValue.name : "");
    }
    setApp((prevUser) => ({ ...prevUser, [name]: newValue }));
  };

  const openModal = (actionType) => {
    setAction(actionType);
    setIsOpen(true);
  };

  const handleSaveApp = async (e) => {
    if (e) e.preventDefault();

    setLoading(true);
    const method = isDetailView ? "PUT" : "POST";

    try {
      let response;

      if (method === "PUT") {
        if (!app.name) {
          toast.error("어플리케이션명을 입력해주세요", {
            autoClose: 3000,
          });
          return;
        }
        response = await Api.Application.update({
          applicationsIdx: app.applicationsIdx,
          name: app.name,
          displayFlag: app.displayFlag,
          file: app.file,
        });
      } else if (method === "POST") {
        if (!app.name || !app.file) {
          toast.error("어플리케이션명과 이미지를 입력해주세요", {
            autoClose: 3000,
          });
          return;
        }
        response = await Api.Application.add({
          name: app.name,
          displayFlag: app.displayFlag,
          file: app.file,
        });
      }

      const successMessage = getLocalizedMessage(
        response,
        DEFAULT_SUCCESS_MESSAGE,
      );
      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        if (isDetailView) {
          navigate(`/app-type${location.search}`);
        } else {
          navigate("/app-type");
        }
      }, 1000);
    } catch (error) {
      const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteApp = async () => {
    setLoading(true);
    try {
      const response = await Api.Application.delete(applicationsIdx);
      const successMessage = getLocalizedMessage(
        response,
        DEFAULT_SUCCESS_MESSAGE,
      );
      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        navigate("/app-type");
      }, 1000);
    } catch (error) {
      console.error("Error deleting application:", error);
      const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(`/app-type${location.search}`);
  };

  return (
    <>
      <PageContainer
        breadcrumbItems={breadcrumbItems}
        title="App Type Management"
      >
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <h4 className="card-title">
                  어플리케이션 타입정보를 입력해주세요.
                </h4>
                <AvForm className="mt-4">
                  <Row>
                    <FormInput
                      id="name"
                      label="어플리케이션명"
                      placeholder="어플리케이션명을 입력해주세요"
                      type="text"
                      value={app.name || ""}
                      onChange={handleChange}
                      errorMessage="어플리케이션명을 입력해주세요"
                      validate={{ required: { value: true } }}
                      fullWidthLabel={false}
                      isRequired
                    />
                  </Row>
                  <Row className="mb-3">
                    <Label
                      htmlFor="customFileInput"
                      className="col-md-2 col-form-label"
                    >
                      이미지(<span className="text-danger">*</span>)
                    </Label>
                    <CustomFileInput
                      fileName={fileName}
                      onChange={handleChange}
                    />
                  </Row>
                  {isDetailView && !app.file && (
                    <Row className="mb-2">
                      <Col>
                        <Label
                          htmlFor="example-tel-input"
                          className="col-md-2 col-form-label"
                        />
                        <CardImgContainer
                          style={{ marginLeft: "4px" }}
                          src={app.imgPath}
                          alt="Application"
                          className="rounded avatar-lg"
                        />
                      </Col>
                    </Row>
                  )}
                  <Row className="mb-2">
                    <Checkbox
                      label="표시여부"
                      id="displayFlag"
                      checked={app.displayFlag === 1 ? true : false}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setApp({ ...app, displayFlag: 1 });
                        } else {
                          setApp({ ...app, displayFlag: 0 });
                        }
                      }}
                      extraClass="mt-2"
                      fullWidthLabel={false}
                    />
                  </Row>
                  <FormButtons
                    isDetailView={isDetailView}
                    openModal={openModal}
                    handleCancel={handleCancel}
                    hideDeleteButton={true}
                  />
                  <ConfirmModal
                    isLoading={loading}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    action={action}
                    handleSave={handleSaveApp}
                    handleDelete={handleDeleteApp}
                  />
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </PageContainer>
    </>
  );
};

export default AppTypeForm;
