import React from "react";

import {} from "../../store/actions";

//Simple bar
import SimpleBar from "simplebar-react";

import SidebarContent from "./SidebarContent";
import withRouter from "../Common/withRouter";

const Sidebar = (props) => {
  return (
    <div className="vertical-menu">
      <div data-simplebar className="h-100">
        {props.type !== "condensed" ? (
          <SimpleBar style={{ maxHeight: "100%" }}>
            <SidebarContent />
          </SimpleBar>
        ) : (
          <SidebarContent />
        )}
      </div>
    </div>
  );
};

export default withRouter(Sidebar);
