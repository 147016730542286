import React, { useState, useRef } from "react";

const OtpInput = ({ length = 6, onChange }) => {
  const [otp, setOtp] = useState(Array(length).fill(""));
  const inputs = useRef([]);

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value.substring(0, 1);
      setOtp(newOtp);
      onChange(newOtp.join(""));
      if (value && index < length - 1) {
        inputs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputs.current[index - 1].focus();
    } else if (e.key === "ArrowLeft" && index > 0) {
      e.preventDefault();
      inputs.current[index - 1].focus();
    } else if (e.key === "ArrowRight" && index < length - 1) {
      inputs.current[index + 1].focus();
    }
  };

  const handleFocus = (index) => {
    const input = inputs.current[index];
    if (input) {
      input.setSelectionRange(input.value.length, input.value.length);
    }
  };

  const handlePaste = (e) => {
    const pasteData = e.clipboardData
      .getData("text")
      .replace(/\D/g, "")
      .slice(0, length);

    const newOtp = Array(length).fill("");
    pasteData.split("").forEach((char, index) => {
      newOtp[index] = char;
    });

    setOtp(newOtp);
    onChange(newOtp.join(""));

    if (inputs.current[newOtp.length - 1]) {
      inputs.current[newOtp.length - 1].focus();
    }
  };

  return (
    <div
      onPaste={handlePaste}
      style={{ display: "flex", gap: "5px", justifyContent: "center" }}
    >
      {otp.map((digit, index) => (
        <input
          className="form-control otp-input mt-5"
          key={index}
          type="text"
          inputMode="numeric"
          pattern="\d*"
          maxLength="1"
          value={digit}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onFocus={() => handleFocus(index)}
          ref={(el) => (inputs.current[index] = el)}
          style={{
            width: "50px",
            textAlign: "center",
            fontSize: "30px",
          }}
        />
      ))}
    </div>
  );
};

export default OtpInput;
