import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table";
import {
  Table,
  Row,
  Col,
  Button,
  Pagination,
  PaginationItem,
  PaginationLink,
  Card,
  CardBody,
  Spinner,
  Input,
} from "reactstrap";
import { DefaultColumnFilter } from "./filters";
import JobListGlobalFilter from "../../components/Common/GlobalSearchFilter";
import { CheckboxCell } from "../../pages/Utility/CustomCells";

const pageSizeOptions = [
  { label: "10개씩 보기", value: 10 },
  { label: "20개씩 보기", value: 20 },
  { label: "30개씩 보기", value: 30 },
  { label: "40개씩 보기", value: 40 },
  { label: "50개씩 보기", value: 50 },
  { label: "100개씩 보기", value: 100 },
  { label: "200개씩 보기", value: 200 },
  { label: "300개씩 보기", value: 300 },
  { label: "500개씩 보기", value: 500 },
];

const PAGE_NUMBER_LIMIT = 10;

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <React.Fragment>
      <Col md={4}>
        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              <input
                onChange={(e) => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`${count} records...`}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
      {isJobListGlobalFilter && <JobListGlobalFilter />}
    </React.Fragment>
  );
}

const CustomTableContainer = ({
  handleAddItem,
  columns,
  data,
  btnTitle,
  isLoading,
  sortByIdx,
  isGlobalFilter,
  isJobListGlobalFilter,
  isAddOptions,
  isAddUserList,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  className,
  customPageSizeOptions,
  totalPage,
  totalRecord,
  setCustomPageSize,
  currentPage,
  setCurrentPage,
  tableClassname,
  theadClassname,
  openInputModal,
  btnTitle1,
  btnIcon1,
  handleClickBtn1,
  selectedIds,
  handleCheckboxChange,
  handleCheckAll,
  showCheckboxColumn = false,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    // pageCount,
    gotoPage,
    // nextPage,
    // previousPage,
    setPageSize,
    state,

    preGlobalFilteredRows,
    setGlobalFilter,

    // state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: currentPage,
        pageSize: customPageSize,
        sortBy: [{ id: sortByIdx, desc: true }],
      },
      manualPagination: true,
      pageCount: totalPage,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        if (!showCheckboxColumn) {
          return columns; // If showCheckboxColumn is false don't show it
        }
        return [
          {
            id: "selection",
            Header: () => (
              <div>
                <Input
                  type="checkbox"
                  className="form-check-input input-mini"
                  checked={
                    (selectedIds?.length || 0) > 0 &&
                    (selectedIds?.length || 0) === data.length
                  }
                  onChange={handleCheckAll}
                />
              </div>
            ),
            Cell: ({ row }) => (
              <div>
                <CheckboxCell
                  itemIdx={row.original[sortByIdx]}
                  selectedIds={selectedIds || []}
                  onCheckboxChange={handleCheckboxChange || (() => {})}
                />
              </div>
            ),
          },
          ...columns,
        ];
      });
    },
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <i className="ri-arrow-down-s-line"></i>
      ) : (
        <i className="ri-arrow-up-s-line"></i>
      )
    ) : (
      ""
    );
  };

  const onChangeInSelect = (event) => {
    const pageSize = Number(event.target.value);
    setPageSize(pageSize);
    setCustomPageSize(pageSize);
  };

  const handlePageClick = (pageIndex) => {
    if (pageIndex !== currentPage) {
      setCurrentPage(pageIndex);
      gotoPage(pageIndex);
    }
  };

  const startPage =
    Math.floor(state.pageIndex / PAGE_NUMBER_LIMIT) * PAGE_NUMBER_LIMIT;
  const endPage = Math.min(startPage + PAGE_NUMBER_LIMIT, pageOptions.length);

  return (
    <Fragment>
      <Col xs={12} style={{ padding: "0 6px" }}>
        <Card>
          <CardBody>
            <Row className="mb-2 d-flex">
              <Col xs={6}>
                <Button
                  color="success"
                  type="button"
                  className="waves-effect waves-light btn-sm"
                  onClick={handleAddItem}
                >
                  <i className="ri-add-line me-2"></i>
                  {btnTitle}
                </Button>
                {btnTitle1 && (
                  <Button
                    color="success"
                    type="button"
                    className="waves-effect waves-light btn-sm ms-1"
                    onClick={handleClickBtn1}
                  >
                    <i className={btnIcon1}></i>
                    {btnTitle1}
                  </Button>
                )}
              </Col>

              <Col xs={6}>
                <div
                  className="d-flex justify-content-end align-items-center"
                  style={{ alignItems: "center" }}
                >
                  <p className="mb-0 me-0 ">Total {totalRecord}</p>
                  <Col
                    md={customPageSizeOptions ? 2 : 1.5}
                    className="d-flex justify-content-end"
                  >
                    <select
                      className="form-select form-select-sm ms-2 cursor-pointer"
                      value={state.pageSize}
                      onChange={onChangeInSelect}
                    >
                      {pageSizeOptions.map((pageSize) => (
                        <option key={pageSize.value} value={pageSize.value}>
                          {pageSize.label}
                        </option>
                      ))}
                    </select>
                  </Col>
                </div>
              </Col>
            </Row>
            {isGlobalFilter && (
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                isJobListGlobalFilter={isJobListGlobalFilter}
              />
            )}
            {isAddOptions && (
              <Col sm="7">
                <div className="text-sm-end">
                  <Button
                    type="button"
                    color="success"
                    className="btn-rounded mb-2 me-2"
                    onClick={handleOrderClicks}
                  >
                    <i className="mdi mdi-plus me-1" />
                    Add New Order
                  </Button>
                </div>
              </Col>
            )}
            {isAddUserList && (
              <Col sm="7">
                <div className="text-sm-end">
                  <Button
                    type="button"
                    color="primary"
                    className="btn mb-2 me-2"
                    onClick={handleUserClick}
                  >
                    <i className="mdi mdi-plus-circle-outline me-1" />
                    Create New User
                  </Button>
                </div>
              </Col>
            )}
            {isAddCustList && (
              <Col sm="7">
                <div className="text-sm-end">
                  <Button
                    type="button"
                    color="success"
                    className="btn-rounded mb-2 me-2"
                    onClick={handleCustomerClick}
                  >
                    <i className="mdi mdi-plus me-1" />
                    New Customers
                  </Button>
                </div>
              </Col>
            )}
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center ">
                <Spinner className="me-2" color="secondary" />
              </div>
            ) : data.length === 0 ? (
              <Row className="mt-5 mb-5">
                <Col className="col-12 text-center">😱</Col>
                <Col className="col-12 text-center">
                  <p>검색조건에 해당하는 데이터가 없어요!</p>
                </Col>
              </Row>
            ) : (
              <>
                <div className="table-responsive react-table ">
                  <Table
                    bordered
                    hover
                    {...getTableProps()}
                    className={className}
                  >
                    <thead className="table-light table-nowrap">
                      {headerGroups.map((headerGroup) => (
                        <tr
                          key={headerGroup.id}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map((column) => (
                            <th key={column.id}>
                              <div
                                className="d-flex"
                                {...(column.id !== "checked"
                                  ? column.getSortByToggleProps()
                                  : {})}
                              >
                                <div
                                  style={{
                                    justifyContent: "center",
                                    textAlign: "center",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  {column.id !== "checked" &&
                                    generateSortingIndicator(column)}

                                  {column.id === "checked" ? (
                                    <Input
                                      type="checkbox"
                                      className="form-check-input input-mini"
                                      checked={
                                        selectedIds.length > 0 &&
                                        selectedIds.length === data.length
                                      }
                                      onChange={handleCheckAll}
                                    />
                                  ) : (
                                    column.render("Header")
                                  )}
                                </div>
                              </div>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyProps()} className="text-center">
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <Fragment key={row.getRowProps().key}>
                            <tr>
                              {row.cells.map((cell) => {
                                return (
                                  <td
                                    key={cell.id}
                                    {...cell.getCellProps()}
                                    className="longTextTrim"
                                    title={cell.value || ""}
                                  >
                                    {cell.column.id === "checked" ? (
                                      <CheckboxCell
                                        itemIdx={row.original[sortByIdx]}
                                        selectedIds={selectedIds}
                                        onCheckboxChange={handleCheckboxChange}
                                      />
                                    ) : (
                                      cell.render("Cell")
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
                <div className="mt-4 d-flex justify-content-center align-items-center cursor-pointer">
                  {totalPage > 1 && (
                    <Pagination
                      aria-label="Page navigation example"
                      className="pagination-rounded"
                    >
                      <PaginationItem disabled={!canPreviousPage}>
                        <PaginationLink
                          previous
                          onClick={() => handlePageClick(state.pageIndex - 1)}
                        />
                      </PaginationItem>
                      {Array.from(
                        { length: endPage - startPage },
                        (_, index) => {
                          const pageIndex = startPage + index;
                          return (
                            <PaginationItem
                              key={pageIndex}
                              active={pageIndex === state.pageIndex}
                            >
                              <PaginationLink
                                onClick={() => handlePageClick(pageIndex)}
                              >
                                {pageIndex + 1}
                              </PaginationLink>
                            </PaginationItem>
                          );
                        },
                      )}
                      <PaginationItem disabled={!canNextPage}>
                        <PaginationLink
                          next
                          onClick={() => handlePageClick(state.pageIndex + 1)}
                        />
                      </PaginationItem>
                    </Pagination>
                  )}
                </div>
              </>
            )}
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

CustomTableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default CustomTableContainer;
