import { create } from "zustand";
import { isTokenValid } from "../utils/isTokenValid";

const useStore = create((set, get) => {
  const storedToken = localStorage.getItem("accessToken");
  const storedExpireTime = localStorage.getItem("expireTime");
  const token = storedToken === "null" ? null : storedToken;
  const expireTime = storedExpireTime ? parseInt(storedExpireTime, 10) : null;

  return {
    token: token,
    expireTime: expireTime,
    loginError: null,
    loading: false,
    users: [],
    setLoading: (loading) => set({ loading }),
    setToken: (token, expireTime) => {
      localStorage.setItem("accessToken", token);
      if (expireTime) {
        localStorage.setItem("expireTime", expireTime.toString());
      } else {
        localStorage.removeItem("expireTime");
      }
      set({ token, expireTime: expireTime || null });
    },
    setLoginError: (error) => set({ loginError: error }),
    setUsers: (users) => set({ users }),
    logout: () => {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("expireTime");
      set({ token: null, expireTime: null });
    },
    isTokenExpired: () => {
      const { token } = get();
      return !isTokenValid(token);
    },
  };
});

export default useStore;
