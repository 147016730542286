import React, { useEffect, useState } from "react";
import { Col, Label } from "reactstrap";

const CustomInputField = ({
  label,
  unit,
  id,
  type,
  value,
  onChange,
  placeholder,
  isRequired,
  min,
  max,
  step,
  // error,
  onValidationError,
  disabled = false,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const getDecimalPlaces = (num) => {
    const match = ("" + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
    if (!match) {
      return 0;
    }
    return Math.max(
      0,
      // Number of digits right of decimal point.
      (match[1] ? match[1].length : 0) -
        // Adjust for scientific notation.
        (match[2] ? +match[2] : 0),
    );
  };

  const validateInput = (inputValue) => {
    let numericValue;
    if (type === "number") {
      numericValue = parseFloat(inputValue);
    } else {
      numericValue = inputValue;
    }

    const stepDecimalPlaces = getDecimalPlaces(step);
    const valueDecimalPlaces = getDecimalPlaces(numericValue);

    // Convert min and max to floats if they are defined
    const minFloat = min !== undefined ? parseFloat(min) : undefined;
    const maxFloat = max !== undefined ? parseFloat(max) : undefined;

    if (minFloat !== undefined && numericValue < minFloat) {
      const error = `Minimum value is ${min}`;
      setErrorMessage(error);
      onValidationError(id, error); // Notify parent
      return false;
    }

    if (maxFloat !== undefined && numericValue > maxFloat) {
      const error = `Maximum value is ${max}`;
      setErrorMessage(error);
      onValidationError(id, error); // Notify parent
      return false;
    }

    if (step !== undefined && valueDecimalPlaces > stepDecimalPlaces) {
      const error = `Decimal places must be less than or equal to ${stepDecimalPlaces}`;
      setErrorMessage(error);
      onValidationError(id, error);
      return false;
    }

    setErrorMessage("");
    onValidationError(id, "");
    return true;
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    setIsEditing(true);
    // Check if the input field is cleared (empty string)
    if (newValue === "") {
      onChange({
        target: {
          name: id,
          value: null, // Set value to null when input is empty
        },
      });
      setIsEditing(false);
      return;
    }

    // Validate and update the value
    onChange({
      target: {
        name: id,
        value: type === "number" ? parseFloat(newValue) : newValue,
      },
    });
  };

  const handleBlur = () => {
    setIsEditing(false);
    validateInput(value);
    onChange({
      target: {
        name: id,
        value:
          value === "" ? null : type === "number" ? parseFloat(value) : value,
      },
    });
  };

  useEffect(() => {
    if (!isEditing) {
      validateInput(value);
    }
  }, [value, min, max, step]);

  return (
    <Col>
      <Label htmlFor={id} className="col-md-2 col-form-label w-100">
        {label}
        {unit && `(${unit})`}
        {isRequired && (
          <>
            (<span className="text-danger">*</span>)
          </>
        )}
      </Label>
      <Col>
        <input
          type={type}
          id={id}
          name={id}
          value={value === null ? "" : value}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={placeholder}
          className={`form-control ${disabled ? "bg-light text-muted" : ""}`}
          disabled={disabled}
        />
        {errorMessage && <div className="text-danger">{errorMessage}</div>}
      </Col>
    </Col>
  );
};

export default CustomInputField;
