import React, { useEffect } from "react";
import * as Api from "@metamorp/api-back";

import useStore from "../../zustandStore";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();
  const setToken = useStore((state) => state.setToken);

  useEffect(() => {
    setTimeout(() => {
      Api.environment.setAccessToken("");
      localStorage.removeItem("accessToken");
      setToken("");
      navigate("/login");
    }, [100]);
  }, [navigate, setToken]);

  return (
    <React.Fragment>
      <h1>&nbsp;</h1>
    </React.Fragment>
  );
};

export default Logout;
