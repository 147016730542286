import React from "react";
import { Button, FormGroup } from "reactstrap";

const FormButtons = ({
  isDetailView,
  openModal,
  handleCancel,
  showCopyButton,
  hideDeleteButton,
  showDownloadButton,
  downloadFileName,
  handleDownload,
}) => (
  <FormGroup>
    <div className="d-flex justify-content-end">
      {isDetailView && showDownloadButton && (
        <Button
          type="button"
          color="info"
          className="me-2"
          onClick={handleDownload}
        >
          {downloadFileName} 다운로드
        </Button>
      )}
      <Button
        type="button"
        color="primary"
        className="me-2"
        onClick={() => openModal(isDetailView ? "edit" : "save")}
      >
        {isDetailView ? "수정" : "저장"}
      </Button>
      {isDetailView && showCopyButton && (
        <Button
          type="button"
          color="success"
          className="me-2"
          onClick={() => openModal("copy")}
        >
          복사
        </Button>
      )}
      {isDetailView && !hideDeleteButton && (
        <Button
          type="button"
          color="danger"
          className="me-2"
          onClick={() => openModal("delete")}
        >
          삭제
        </Button>
      )}
      <Button type="reset" color="secondary" onClick={handleCancel}>
        취소
      </Button>
    </div>
  </FormGroup>
);

export default FormButtons;
