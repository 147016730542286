import { registrationOptions, showOptions } from "./Options";
import { ImgContainer, ListHoverImage } from "./styled";
import noImg from "../../assets/images/no-image.jpg";
import materialImg from "../../assets/images/default-material.png";
import printerImg from "../../assets/images/default-printer.png";
import React from "react";
import { Input } from "reactstrap";
import { osTypeOptions } from "../Version";

export const CheckboxCell = React.memo(
  ({ itemIdx, selectedIds, onCheckboxChange }) => {
    return (
      <Input
        type="checkbox"
        className="form-check-input input-mini"
        id={`row-checkbox-${itemIdx}`}
        checked={selectedIds?.includes(itemIdx)}
        onChange={() => onCheckboxChange(itemIdx)}
      />
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.row === nextProps.row &&
      prevProps.selectedIds === nextProps.selectedIds
    );
  },
);

export const ClickableCell = ({ value, row, handleRowClick }) => {
  return (
    <span
      style={{ textDecoration: "underline", cursor: "pointer" }}
      onClick={() => handleRowClick(row)}
    >
      {value}
    </span>
  );
};

export const ImageCell = ({ value, isUser, type = "" }) => {
  let defaultImg;

  if (type === "material") {
    defaultImg = materialImg;
  } else if (type === "printer") {
    defaultImg = printerImg;
  } else {
    defaultImg = noImg;
  }

  return value ? (
    <ImgContainer>
      {isUser ? (
        <img
          src={value}
          alt=""
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
      ) : (
        <img src={value} alt="" />
      )}
      <ListHoverImage
        style={{
          backgroundImage: `url(${value})`,
        }}
      />
    </ImgContainer>
  ) : (
    <div>
      <img
        src={defaultImg}
        alt=""
        style={{ width: "50px", height: "50px", border: "none" }}
      />
    </div>
  );
};

// export default ImageCell;

export const RegTypeCell = ({ value }) => {
  let color = "";
  switch (value) {
    case 0: // 사용자등록
      color = "#ff3d5f";
      break;
    case 1: // 기본
      color = "black";
      break;
    default:
      color = "black";
      break;
  }
  return (
    <span style={{ color: color }}>
      {registrationOptions.find((option) => option.value === value)?.label}
    </span>
  );
};

export const DisplayCell = ({ value }) => {
  let color = "";
  switch (value) {
    case 0: // 숨김
      color = "#75788d";
      break;
    case 1: // 표시
      color = "#1cbb8c";
      break;
    default:
      color = "black";
      break;
  }

  return (
    <span style={{ color: color }}>
      {showOptions.find((option) => option.value === value)?.label}
    </span>
  );
};

export const OsTypeCell = ({ value }) => {
  let color = "";
  switch (value) {
    case "WIN": // 숨김
      color = "#ff3d5f";
      break;
    case "MAC": // 표시
      color = "#fcb92c";
      break;
    default:
      color = "black";
      break;
  }

  return (
    <span style={{ color: color }}>
      {osTypeOptions.find((option) => option.value === value)?.value}
    </span>
  );
};
