import CustomTableContainer from "../../components/Common/CustomTableContainer";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ControlPanel from "../../components/Common/ControlPanel";
import PageContainer from "../../components/Common/PageContainer";
import { useNavigate } from "react-router-dom";
import useMaterialStore from "../../zustandStore/materialStore";
import { format } from "date-fns";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import * as Api from "@metamorp/api-back";
import useStore from "../../zustandStore";
import {
  ClickableCell,
  DisplayCell,
  ImageCell,
  RegTypeCell,
} from "../Utility/CustomCells";
import { registrationOptions, showOptions } from "../Utility/Options";

const BREADCRUMB_ITEMS = [
  { title: "Material Management", link: "#" },
  { title: "List", link: "#" },
];

const Material = () => {
  const { token } = useStore();
  const { materials, setMaterials, loading, setLoading, setError } =
    useMaterialStore();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const [customPageSize, setCustomPageSize] = useState(
    parseInt(queryParams.pageSize) || 10,
  );
  const [currentPage, setCurrentPage] = useState(
    parseInt(queryParams.page) || 0,
  );
  const [totalPage, setTotalPage] = useState(0);
  const [totalMaterials, setTotalMaterials] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(queryParams.keyword || "");
  const [dateRange, setDateRange] = useState([
    queryParams.startDt || "",
    queryParams.endDt || "",
  ]);
  const [selectedMaterial, setSelectedMaterial] = useState(
    queryParams.material || registrationOptions[0].value,
  );
  const [selectedShow, setSelectedShow] = useState(
    queryParams.show || showOptions[0].value,
  );
  const [clearTrigger, setClearTrigger] = useState(false);

  const handleRowClick = useCallback(
    (item) => {
      navigate(`/material/${item.materialIdx}${window.location.search}`);
    },
    [navigate],
  );

  const handleAddMaterial = () => {
    navigate(`/material/new${window.location.search}`);
  };

  useEffect(() => {
    const params = {
      page: currentPage,
      pageSize: customPageSize,
      keyword: searchKeyword,
      startDt: dateRange[0],
      endDt: dateRange[1],
      material: selectedMaterial,
      show: selectedShow,
    };
    navigate(`${location.pathname}?${queryString.stringify(params)}`);
  }, [
    currentPage,
    customPageSize,
    dateRange,
    searchKeyword,
    selectedMaterial,
    selectedShow,
    location.pathname,
    navigate,
  ]);

  const fetchMaterials = async () => {
    setLoading(true);
    setError(null);

    try {
      if (token) {
        Api.environment.setAccessToken(token);
      }
      const requestData = {
        pageSize: customPageSize,
        nowPage: currentPage + 1,
        pageGroup: 10,
        keyword: searchKeyword,
        displayFlag: selectedShow,
        defaultFlag: selectedMaterial,
        startDt: dateRange[0] ? format(dateRange[0], "yyyy-MM-dd") : "",
        endDt: dateRange[1] ? format(dateRange[1], "yyyy-MM-dd") : "",
      };

      const response = await Api.Material.getList(requestData);
      if (response.code !== 0) {
        throw new Error(response.message);
      }
      setTotalPage(response.paging.totalPage);
      setTotalMaterials(response.paging.totalRecord);
      setMaterials(response.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMaterials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    token,
    currentPage,
    customPageSize,
    selectedMaterial,
    selectedShow,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dateRange[1],
    clearTrigger,
  ]);

  const handleSearch = () => {
    setCurrentPage(0);
    fetchMaterials();
  };

  const handleMaterialSearch = (newMaterial) => {
    setCurrentPage(0);
    setSelectedMaterial(newMaterial);
  };

  const handleShowSearch = (newShow) => {
    setCurrentPage(0);
    setSelectedShow(newShow);
  };

  const handleDateSearch = (newDateRange) => {
    setDateRange(newDateRange);
    setCurrentPage(0);
  };

  const clearFilters = () => {
    setSearchKeyword("");
    setDateRange("");
    setSelectedMaterial(registrationOptions[0].value);
    setSelectedShow(showOptions[0].value);
    setCurrentPage(0);
    setClearTrigger((prev) => !prev);
  };

  const columns = useMemo(
    () => [
      {
        Header: "No",
        accessor: "materialIdx",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "이미지",
        accessor: "imgPath",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }) => (
          <ImageCell value={value} type="material" />
        ),
      },
      {
        Header: "소재명",
        accessor: "materialName",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value }, row }) => (
          <ClickableCell
            value={value}
            row={row.original}
            handleRowClick={handleRowClick}
          />
        ),
      },
      {
        Header: "제조사명",
        accessor: "manufName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "기본소재여부",
        accessor: "defaultFlag",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }) => <RegTypeCell value={value} />,
      },
      {
        Header: "표시여부",
        accessor: "displayFlag",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }) => <DisplayCell value={value} />,
      },
      {
        Header: "등록자",
        accessor: "regName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "등록일자",
        accessor: "regDt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정자",
        accessor: "updName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "수정일자",
        accessor: "updDt",
        disableFilters: true,
        filterable: false,
      },
    ],
    [handleRowClick],
  );

  return (
    <React.Fragment>
      <PageContainer
        breadcrumbItems={BREADCRUMB_ITEMS}
        title="Material Management"
      >
        <ControlPanel
          placeholder={
            "소재명, 제조사명, 등록자, 수정자명 등으로 검색해주세요."
          }
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          clearFilters={clearFilters}
          onSearch={handleSearch}
          dateRange={dateRange}
          setDateRange={handleDateSearch}
          options1={registrationOptions}
          options2={showOptions}
          selectedOption1={selectedMaterial}
          setSelectedOption1={handleMaterialSearch}
          selectedOption2={selectedShow}
          setSelectedOption2={handleShowSearch}
          selectTitle1="기본소재여부"
          selectTitle2="표시여부"
        />
        <CustomTableContainer
          sortByIdx="materialIdx"
          btnTitle="소재 등록"
          handleAddItem={handleAddMaterial}
          handleRowClick={handleRowClick}
          columns={columns || []}
          data={materials || []}
          isPagination={true}
          customPageSize={customPageSize}
          totalPage={totalPage}
          totalRecord={totalMaterials}
          setCustomPageSize={setCustomPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isLoading={loading}
          className="custom-header-css table align-middle table-nowrap"
          tableClassName="table-centered align-middle table-nowrap mb-0"
          theadClassName="text-muted table-light"
        />
      </PageContainer>
    </React.Fragment>
  );
};

export default Material;
