import { OsType } from "./VersionForm";

export const extractFileDetails = (
  fileName: string,
): { versionName: string; postfix: string; osType: OsType | null } => {
  const versionNameMatch = fileName.match(
    /metamorp(?:-\w+)*-(\d+\.\d+\.\d+[-\w]*\.\d*)/,
  );
  const versionName = versionNameMatch ? versionNameMatch[1] : "";

  const postfixMatch = fileName.match(
    /metamorp-(.*?)-\d+\.\d+\.\d+[-\w]*\.\d*/,
  );
  const postfix = postfixMatch ? postfixMatch[1] : "";

  let osType: OsType | null = null;

  if (fileName.includes("-x64")) {
    osType = "WIN";
  } else if (fileName.includes("-arm64")) {
    osType = "MAC";
  }

  return { versionName, postfix, osType };
};
