import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEn from "./locales/en/translation.json";
import translationKo from "./locales/ko/translation.json";
import translationRu from "./locales/ru/translation.json";

const resources = {
  en: {
    translation: translationEn,
  },
  ko: {
    translation: translationKo,
  },
  ru: {
    translation: translationRu,
  },
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "ko", // default language
    fallbackLng: "ko", // fallback language
    // supportedLngs: ["en", "ko", "ru"], // add this line
    keySeparator: false, // don't use keys
    interpolation: {
      escapeValue: false, // react already escapes values
    },
  });

export default i18n;
