import React from "react";
import MaterialForm from "./MaterialForm";

const MaterialDetails = () => {
  return (
    <div>
      <MaterialForm isDetailView />
    </div>
  );
};

export default MaterialDetails;
