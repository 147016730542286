export const registrationOptions = [
  { label: "전체", value: -1 },
  { label: "사용자등록", value: 0 },
  { label: "기본", value: 1 },
];

export const showOptions = [
  { label: "전체", value: -1 },
  { label: "숨김", value: 0 },
  { label: "표시", value: 1 },
];
