export const PARAMS = [
  {
    group: "Hard",
    params: [
      {
        label: "범프 높이",
        placeholder: "범프 높이를 입력해주세요",
        name: "bumpHeight",
        unit: "mm",
        type: "number",
        min: 0,
        max: (values) => values.pinLength || 10,
        decimal: 0.01,
      },
      {
        label: "핀 깊이",
        placeholder: "핀 깊이를 입력해주세요",
        name: "pinDepth",
        type: "number",
        unit: "mm",
        min: 0,
        max: 10,
        decimal: 0.01,
      },
      {
        label: "핀 상단 지름",
        placeholder: "핀 상단 지름을 입력해주세요",
        name: "pinTopDiameter",
        type: "number",
        unit: "mm",
        min: 0.01,
        max: 10,
        decimal: 0.01,
      },
      {
        label: "핀 하단 지름",
        placeholder: "핀 하단 지름을 입력해주세요",
        name: "pinBottomDiameter",
        type: "number",
        unit: "mm",
        min: 0.01,
        max: 10,
        decimal: 0.01,
      },
      {
        label: "핀 길이",
        placeholder: "핀 길이을 입력해주세요",
        name: "pinLength",
        type: "number",
        unit: "mm",
        min: 0.01,
        max: 10,
        decimal: 0.01,
      },
      {
        label: "핀 조인트 지름",
        placeholder: "핀 조인트 지름을 입력해주세요",
        name: "pinJointDiameter",
        type: "number",
        unit: "mm",
        min: (values) => values.pinBottomDiameter || 10,
        max: 10,
        decimal: 0.01,
      },
      {
        label: "수직핀사용",
        name: "useVerticalPin",
        type: "boolean",
      },
    ],
  },
  {
    group: "Supporting Point",
    params: [
      {
        label: "복셀 크기",
        placeholder: "복셀 크기를 입력해주세요",
        name: "voxelSize",
        type: "number",
        unit: "mm",
        min: 1,
        max: 20,
        decimal: 0.01,
      },
      {
        label: "인필 거리",
        placeholder: "인필 거리를 입력해주세요",
        name: "infillDistance",
        type: "number",
        unit: "mm",
        min: 0.1,
        max: 10,
        decimal: 0.01,
      },
      {
        label: "오버행 각도",
        placeholder: "오버행 각도를 입력해주세요",
        name: "overhangAngle",
        type: "number",
        unit: "deg",
        min: 10,
        max: 80,
        decimal: 0.01,
      },
      {
        label: "보더 오프셋",
        placeholder: "보더 오프셋을 입력해주세요",
        name: "borderOffset",
        type: "number",
        unit: "mm",
        min: 0,
        max: 10,
        decimal: 0.01,
      },
      {
        label: "라인 거리",
        placeholder: "라인 거리를 입력해주세요",
        name: "lineDistance",
        type: "number",
        unit: "mm",
        min: 0.1,
        max: 10,
        decimal: 0.01,
      },
      {
        label: "최소 높이",
        placeholder: "최소 높이를 입력해주세요",
        name: "minimumHeight",
        type: "number",
        unit: "mm",
        min: 0,
        max: 10,
        decimal: 0.01,
      },
    ],
  },
  {
    group: "Body",
    params: [
      {
        label: "브레이스 사용",
        name: "useBrace",
        type: "boolean",
      },
      {
        label: "베이스 사용",
        name: "useBase",
        type: "boolean",
      },
      {
        label: "기둥 상단 지름",
        placeholder: "기둥 상단 지름을 입력해주세요",
        name: "columnTopDiameter",
        type: "number",
        unit: "mm",
        min: 0.01,
        max: 10,
        decimal: 0.01,
      },
      {
        label: "기둥 하단 지름",
        placeholder: "기둥 하단 지름을 입력해주세요",
        name: "columnBottomDiameter",
        type: "number",
        unit: "mm",
        min: 0.01,
        max: 10,
        decimal: 0.01,
      },
      {
        label: "브레이스 지름",
        placeholder: "브레이스 지름을 입력해주세요",
        name: "braceDiameter",
        type: "number",
        unit: "mm",
        min: 0.01,
        max: 10,
        decimal: 0.01,
      },
      {
        label: "베이스 높이",
        placeholder: "베이스 높이를 입력해주세요",
        name: "baseHeight",
        type: "number",
        unit: "mm",
        min: 0.1,
        max: 10,
        decimal: 0.01,
      },
      {
        label: "베이스 지름",
        placeholder: "베이스 지름을 입력해주세요",
        name: "baseDiameter",
        type: "number",
        unit: "mm",
        min: (values) => values.columnBottomDiameter,
        max: (values) => values.columnBottomDiameter + 10,
        decimal: 0.01,
      },
    ],
  },
  {
    group: "Raft",
    params: [
      {
        label: "래프트 사용",
        name: "useRaft",
        type: "boolean",
      },
      {
        label: "래프트 높이",
        placeholder: "래프트 높이를 입력해주세요",
        name: "raftHeight",
        type: "number",
        unit: "mm",
        min: 0.1,
        max: 10,
        decimal: 0.01,
      },
      {
        label: "래프트 오프셋",
        placeholder: "래프트 오프셋을 입력해주세요",
        name: "raftOffset",
        type: "number",
        unit: "mm",
        min: 0,
        max: 10,
        decimal: 0.01,
      },
      {
        label: "래프트 홀 거리",
        placeholder: "래프트 홀 거리를 입력해주세요",
        name: "raftHoleDistance",
        type: "number",
        unit: "mm",
        min: (values) => values.raftHoleDiameter,
        max: (values) => values.raftHoleDiameter + 50,
        decimal: 0.01,
      },
      {
        label: "래프트 홀 지름",
        placeholder: "래프트 홀 지름을 입력해주세요",
        name: "raftHoleDiameter",
        type: "number",
        unit: "mm",
        min: 0,
        max: 10,
        decimal: 0.01,
      },
    ],
  },
];
