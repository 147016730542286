import React from "react";
import AppTypeForm from "./AppTypeForm";

const AppTypeDetails = () => {
  return (
    <div>
      <AppTypeForm isDetailView />;
    </div>
  );
};

export default AppTypeDetails;
