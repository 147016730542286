import React, { useCallback, useEffect, useState } from "react";
import PageContainer from "../../components/Common/PageContainer";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import * as Api from "@metamorp/api-back";
import { useParams } from "react-router-dom";
import { CardImgContainer, HoverImage, RowContainer } from "../Utility/styled";
import ConfirmModal from "../../components/Common/Modal";
import FormButtons from "../../components/Common/FormButtons";
import FormInput from "../../components/Profile/FormInput";
import Checkbox from "../../components/Profile/Checkbox";
import { useLocation } from "react-router-dom";
import useStore from "../../zustandStore";
import useMaterialStore from "../../zustandStore";
import CustomFileInput from "../../components/Common/CustomFileInput";
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
} from "../../helpers/constants";
import { useLocalizedMessage } from "../../helpers/hooks";

const MaterialForm = ({ isDetailView }) => {
  const navigate = useNavigate();
  const { materialIdx } = useParams();
  const { loading, setLoading } = useMaterialStore();
  const location = useLocation();
  const { token } = useStore();
  const getLocalizedMessage = useLocalizedMessage();

  const breadcrumbItems = [
    { title: "Material Management", link: "#" },
    { title: `${isDetailView ? "Edit" : "Add"}`, link: "#" },
  ];
  const [material, setMaterial] = useState({
    materialName: "",
    manufName: "",
    displayFlag: 1,
    file: null,
  });
  const [fileName, setFileName] = useState(material.file?.name || "");
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState("");

  const getMaterialByIdx = useCallback(async () => {
    try {
      if (token) {
        Api.environment.setAccessToken(token);
      }
      const response = await Api.Material.get(materialIdx);
      const existingMaterial = response.data;
      if (existingMaterial) {
        setMaterial(existingMaterial);
      } else {
        setMaterial({
          materialName: "",
          manufName: "",
          displayFlag: 1,
          file: null,
        });
      }
    } catch (error) {
      console.error("Error fetching Material from API", error);
    }
  }, [token, materialIdx]);

  useEffect(() => {
    if (isDetailView) {
      getMaterialByIdx();
    }
  }, [getMaterialByIdx, isDetailView]);

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    const newValue = type === "checkbox" ? checked : files ? files[0] : value;

    if (name === "file") {
      setFileName(newValue ? newValue.name : "");
    }
    setMaterial((prevMaterial) => ({ ...prevMaterial, [name]: newValue }));
  };

  const openModal = (actionType) => {
    setAction(actionType);
    setIsOpen(true);
  };

  const handleSaveMaterial = async (e) => {
    if (e) e.preventDefault();

    if (!material.materialName || !material.manufName) {
      toast.error("소재명과 제조사명을 입력해주세요", {
        autoClose: 3000,
      });
      return;
    }

    setLoading(true);
    const method = isDetailView ? "PUT" : "POST";

    try {
      let response;
      if (method === "PUT") {
        response = await Api.Material.update({
          materialIdx: material.materialIdx,
          materialName: material.materialName,
          manufName: material.manufName,
          displayFlag: material.displayFlag,
          file: material.file,
        });
      } else if (method === "POST") {
        response = await Api.Material.add({
          materialName: material.materialName,
          manufName: material.manufName,
          displayFlag: material.displayFlag,
          file: material.file,
        });
      }

      const successMessage = getLocalizedMessage(
        response,
        DEFAULT_SUCCESS_MESSAGE,
      );
      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        if (isDetailView) {
          navigate(`/material${location.search}`);
        } else {
          navigate("/material");
        }
      }, 1000);
    } catch (error) {
      const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteMaterial = async () => {
    setLoading(true);

    try {
      const response = await Api.Material.delete(material.materialIdx);
      const successMessage = getLocalizedMessage(
        response,
        DEFAULT_SUCCESS_MESSAGE,
      );
      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        navigate("/material");
      }, 1000);
    } catch (error) {
      console.error("Error deleting user:", error);
      const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(`/material${location.search}`);
  };

  return (
    <>
      <PageContainer
        breadcrumbItems={breadcrumbItems}
        title="Material Management"
      >
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <h4 className="card-title">소재 정보를 입력해주세요.</h4>
                <AvForm className="mt-4">
                  <Row>
                    <FormInput
                      id="materialName"
                      label="소재명"
                      placeholder="소재명을 입력해주세요"
                      type="text"
                      value={material.materialName}
                      onChange={handleChange}
                      errorMessage="소재명을 입력해주세요"
                      validate={{ required: { value: true } }}
                      fullWidthLabel={false}
                      isRequired
                    />
                  </Row>
                  <Row>
                    <FormInput
                      id="manufName"
                      label="제조사명"
                      placeholder="제조사명을 입력해주세요"
                      type="text"
                      value={material.manufName}
                      onChange={handleChange}
                      errorMessage="제조사명을 입력해주세요"
                      validate={{ required: { value: true } }}
                      fullWidthLabel={false}
                      isRequired
                    />
                  </Row>

                  <Row className="mb-3">
                    <Label
                      htmlFor="customFileInput"
                      className="col-md-2 col-form-label"
                    >
                      이미지
                    </Label>
                    <CustomFileInput
                      fileName={fileName}
                      onChange={handleChange}
                    />
                  </Row>
                  {isDetailView && !material.file && material.imgPath && (
                    <RowContainer className="mb-2">
                      <Col>
                        <Label
                          htmlFor="example-tel-input"
                          className="col-md-2 col-form-label"
                        />
                        <CardImgContainer
                          src={material.imgPath}
                          alt=""
                          className="avatar-lg"
                        />
                        <HoverImage
                          style={{
                            backgroundImage: `url(${material.imgPath})`,
                          }}
                        />
                      </Col>
                    </RowContainer>
                  )}

                  <Row className="mb-2">
                    <Checkbox
                      label="표시여부"
                      id="displayFlag"
                      checked={material.displayFlag === 1 ? true : false}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setMaterial({ ...material, displayFlag: 1 });
                        } else {
                          setMaterial({ ...material, displayFlag: 0 });
                        }
                      }}
                      extraClass="mt-2"
                      fullWidthLabel={false}
                    />
                  </Row>
                  {isDetailView && (
                    <Row className="mb-2">
                      <Checkbox
                        label="기본소재여부"
                        id="defaultFlag"
                        checked={material.defaultFlag}
                        extraClass="mt-2"
                        fullWidthLabel={false}
                        style={{ opacity: "0.5" }}
                        disabled
                        readOnly
                      />
                    </Row>
                  )}
                  <FormButtons
                    isDetailView={isDetailView}
                    openModal={openModal}
                    handleCancel={handleCancel}
                  />
                  <ConfirmModal
                    isLoading={loading}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    action={action}
                    handleSave={handleSaveMaterial}
                    handleDelete={handleDeleteMaterial}
                  />
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </PageContainer>
    </>
  );
};

export default MaterialForm;
