import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import useLayoutStore from "../../zustandStore/layoutStore";

// Other Layout related Component
import Navbar from "./Navbar";
import Header from "./Header";
import Rightbar from "../CommonForBoth/Rightbar";

const Layout = ({ children }) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const {
    topbarTheme,
    theme,
    layoutWidth,
    isPreloader,
    showRightSidebar,
    changeLayout,
    changeTopbarTheme,
    changeLayoutTheme,
    changeLayoutWidth,
    toggleRightSidebar,
  } = useLayoutStore();

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);

    const title = location.pathname;
    let currentage = title.charAt(1).toUpperCase() + title.slice(2);
    currentage = currentage.replaceAll("-", " ");

    document.title = currentage + " | Metamorp - Admin Dashboard";

    changeLayout("horizontal");
    if (topbarTheme) {
      changeTopbarTheme(topbarTheme);
    }
    if (theme) {
      changeLayoutTheme(theme);
    }
    if (layoutWidth) {
      changeLayoutWidth(layoutWidth);
    }
    if (showRightSidebar) {
      toggleRightSidebar();
    }
  }, [
    location,
    changeLayout,
    changeTopbarTheme,
    changeLayoutTheme,
    changeLayoutWidth,
    showRightSidebar,
    toggleRightSidebar,
    topbarTheme,
    theme,
    layoutWidth,
  ]);

  useEffect(() => {
    if (isPreloader) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";

      setTimeout(() => {
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
      }, 2500);
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }, [isPreloader]);

  const openMenu = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  return (
    <React.Fragment>
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <i className="ri-loader-line spin-icon"></i>
          </div>
        </div>
      </div>

      <div id="layout-wrapper">
        <Header
          theme={topbarTheme}
          isMenuOpened={isMenuOpened}
          toggleRightSidebar={toggleRightSidebar}
          openLeftMenuCallBack={openMenu}
        />
        <Navbar menuOpen={isMenuOpened} />
        <div className="main-content">
          {children}
          {/* <Footer /> */}
        </div>
      </div>
      <Rightbar />
    </React.Fragment>
  );
};

export default Layout;
