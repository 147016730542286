import { useCallback, useEffect, useState } from "react";
import { Card, Col, Label, Row } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import * as Api from "@metamorp/api-back";
import useStore from "../../zustandStore";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import useVersionStore from "../../zustandStore/versionStore";
import Selector from "../../components/Profile/Selector";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Checkbox from "../../components/Profile/Checkbox";
import { toast, ToastContainer } from "react-toastify";
import FormInputTypes from "../../components/Profile/FormInputTypes";
import ConfirmModalTypes from "../../components/Common/ConfirmModalTypes";
import FormButtonsTypes from "../../components/Common/FormButtonsTypes";
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
} from "../../helpers/constants";
import { useLocalizedMessage } from "../../helpers/hooks";
import DraggableFileInput from "../../components/Common/DraggableFileInput";
import { extractFileDetails } from "./utils";
import PageContainer from "../../components/Common/PageContainer";
import CustomCardBody from "./components/CustomCardBody";

type VersionFormProps = {
  isDetailView: boolean;
};

type VersionProps = Omit<Api.Request.AddVersion, "appFile" | "ymlFile"> &
  Partial<
    Pick<Api.Response.Version, "versionIdx" | "fileUrl" | "ymlUrl"> &
      Pick<Api.Request.AddVersion, "appFile" | "ymlFile">
  >;

export type OsType = "WIN" | "MAC";

const osTypeOptions: { label: string; value: string }[] = [
  { label: "WIN", value: "WIN" },
  { label: "MAC", value: "MAC" },
];

const DEFAULT_VERSION: VersionProps = {
  versionName: "",
  postfix: "",
  osType: osTypeOptions[0].value as OsType,
  appFile: null as unknown as Blob,
  ymlFile: null as unknown as Blob,
  improvements: "",
  newFeatures: "",
  bugsFixed: "",
  displayFlag: 0,
};

const VersionForm = ({ isDetailView }: VersionFormProps) => {
  const { loading, setLoading } = useVersionStore();
  const { versionIdx } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useStore();
  const getLocalizedMessage = useLocalizedMessage();

  const breadcrumbItems = [
    { title: "Version Management", link: "#" },
    { title: `${isDetailView ? "Edit" : "Add"}`, link: "#" },
  ];

  const [version, setVersion] = useState(DEFAULT_VERSION);
  const [appFileName, setAppFileName] = useState("");
  const [ymlFileName, setYmlFileName] = useState("");
  const [downloadFileName, setDownloadFileName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState("");

  const getVersionByIdx = useCallback(async () => {
    try {
      if (token) {
        Api.environment.setAccessToken(token);
      }
      if (!versionIdx) return;
      const response = await Api.Version.get(parseInt(versionIdx));
      const existingVersion = response.data;
      if (existingVersion) {
        setVersion(existingVersion);
        setDownloadFileName(existingVersion?.fileUrl?.split("/").pop() || "");
        setAppFileName(existingVersion?.fileUrl?.split("/").pop() || "");
        setYmlFileName(existingVersion?.ymlUrl?.split("/").pop() || "");
      } else {
        setVersion(DEFAULT_VERSION);
      }
    } catch (error) {
      console.error("Error fetching Version from API", error);
    }
  }, [versionIdx, token]);

  useEffect(() => {
    if (isDetailView) {
      getVersionByIdx();
    }
  }, [getVersionByIdx, isDetailView]);

  const handleChange = (e: {
    target: {
      name: string;
      value: string;
      type?: string;
      checked?: boolean;
      files?: FileList | null;
    };
  }) => {
    const { name, value, type, checked, files } = e.target;
    const newValue = type === "checkbox" ? checked : files ? files[0] : value;

    if (newValue instanceof File) {
      const { versionName, postfix, osType } = extractFileDetails(
        newValue.name,
      );

      if (name === "appFile") {
        setAppFileName(newValue.name || "name");
        setVersion((prevVer) => ({
          ...prevVer,
          osType: osType || prevVer.osType,
          appFile: newValue,
          versionName: versionName || "",
          postfix: postfix || "",
        }));
      } else if (name === "ymlFile") {
        setYmlFileName(newValue.name || "");
      }
    } else {
      if (name === "appFile") {
        setAppFileName("");
      } else if (name === "ymlFile") {
        setYmlFileName("");
      }
    }
    setVersion((prevVer) => ({ ...prevVer, [name]: newValue }));
  };

  const handleFilesDropped = (files: FileList) => {
    const filesArray = Array.from(files);

    filesArray.forEach((file) => {
      const fileName = file?.name;

      if (fileName === "latest.yml" || fileName === "latest-mac.yml") {
        setVersion((prevVer: VersionProps) => ({
          ...prevVer,
          ymlFile: file,
        }));
        setYmlFileName(fileName);
      } else {
        const { versionName, postfix, osType } = extractFileDetails(fileName);

        if (osType) {
          setVersion((prevVer: VersionProps) => ({
            ...prevVer,
            osType,
            appFile: file,
            versionName: versionName || "",
            postfix: postfix || "",
          }));
          setAppFileName(fileName);
        }
      }
    });
  };

  useEffect(() => {
    if (
      (version.osType === "MAC" && ymlFileName === "latest.yml") ||
      (version.osType === "WIN" && ymlFileName === "latest-mac.yml")
    ) {
      toast.error("Check latest file!", {
        autoClose: 3000,
      });
    }
  }, [version.osType, ymlFileName]);

  const openModal = (actionType: string) => {
    setAction(actionType);
    setIsOpen(true);
  };

  const handleCancel = () => {
    navigate(`/app-version${location.search}`);
  };

  const handleSaveVersion = async () => {
    if (
      !version.versionName ||
      (!isDetailView && !version.ymlFile) ||
      (!isDetailView && !version.appFile)
    ) {
      toast.error("(*) 필드 모두 입력해주세요", {
        autoClose: 3000,
      });
      return;
    }

    setLoading(true);
    const method = isDetailView ? "PUT" : "POST";

    try {
      const requestData = {
        versionName: version.versionName,
        postfix: version.postfix,
        osType: version.osType,
        appFile: version.appFile,
        ymlFile: version.ymlFile,
        improvements: version.improvements,
        newFeatures: version.newFeatures,
        bugsFixed: version.bugsFixed,
        displayFlag: version.displayFlag,
      };

      let response;
      if (method === "PUT") {
        if (typeof version?.versionIdx !== "number") return;

        response = await Api.Version.update({
          ...(requestData as Api.Request.UpdateVersion),
          versionIdx: (version as Api.Request.UpdateVersion).versionIdx,
        });
      } else if (method === "POST") {
        response = await Api.Version.add(requestData as Api.Request.AddVersion);
      }

      const successMessage = getLocalizedMessage(
        response,
        DEFAULT_SUCCESS_MESSAGE,
      );

      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        if (isDetailView) {
          navigate(`/app-version${location.search}`);
        } else {
          navigate("/app-version");
        }
      }, 1000);
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        console.error("Error: adding new app version", error);
        const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
        toast.error(errMessage, {
          autoClose: 3000,
        });
      }
    } finally {
      setLoading(false);
      setIsOpen(false);
    }
  };

  const [focusedEditors, setFocusedEditors] = useState({
    improvements: false,
    newFeatures: false,
    bugsFixed: false,
  });

  const handleEditorFocus = (editorName: string) => {
    setFocusedEditors((prev) => ({ ...prev, [editorName]: true }));
  };

  const handleEditorBlur = (editorName: string) => {
    setFocusedEditors((prev) => ({ ...prev, [editorName]: false }));
  };

  const handleDownload = (fileUrl: string) => {
    if (!fileUrl) {
      console.error("File URL is not available");
      return;
    }
    window.location.href = fileUrl;
  };

  return (
    <>
      <PageContainer
        breadcrumbItems={breadcrumbItems}
        title="Version Management"
      >
        <Row>
          <Col xs={12}>
            <Card>
              <CustomCardBody onFilesDropped={handleFilesDropped}>
                <h4 className="card-title">앱 정보를 입력해주세요</h4>
                <AvForm className="mt-4">
                  <Row>
                    <FormInputTypes
                      id="versionName"
                      label="버전명"
                      placeholder="버전명을 입력해주세요"
                      type="text"
                      value={version.versionName || ""}
                      onChange={handleChange}
                      initialErrorMessage="버전명을 입력해주세요"
                      validate={{
                        required: {
                          value: true,
                        },
                      }}
                      fullWidthLabel={false}
                      isRequired
                    />
                  </Row>
                  <Row>
                    <FormInputTypes
                      id="postfix"
                      label="Postfix"
                      placeholder="Postfix를 입력해주세요"
                      type="text"
                      value={version.postfix || ""}
                      onChange={handleChange}
                      initialErrorMessage="Postfix를 입력해주세요"
                      fullWidthLabel={false}
                    />
                  </Row>
                  <Row className="mb-3">
                    <Selector
                      label="OS구분"
                      id="osType"
                      options={osTypeOptions}
                      value={version.osType}
                      onChange={handleChange}
                      isRequired
                    />
                  </Row>
                  <Row className="mb-3">
                    <Label
                      htmlFor="appFile"
                      className="col-md-2 col-form-label"
                    >
                      APP 파일(<span className="text-danger">*</span>)
                    </Label>
                    {isDetailView && version?.fileUrl ? (
                      <DraggableFileInput
                        name="appFile"
                        fileName={
                          appFileName ?? version?.fileUrl.split("/").pop()
                        }
                        onChange={handleChange}
                      />
                    ) : (
                      <DraggableFileInput
                        name="appFile"
                        fileName={appFileName}
                        onChange={handleChange}
                        handleFilesDropped={handleFilesDropped}
                      />
                    )}
                  </Row>
                  <Row className="mb-3">
                    <Label
                      htmlFor="ymlFile"
                      className="col-md-2 col-form-label"
                    >
                      latest 파일(<span className="text-danger">*</span>)
                    </Label>
                    {isDetailView && version?.ymlUrl ? (
                      <DraggableFileInput
                        name="ymlFile"
                        fileName={
                          ymlFileName ?? version?.ymlUrl.split("/").pop()
                        }
                        onChange={handleChange}
                      />
                    ) : (
                      <DraggableFileInput
                        name="ymlFile"
                        fileName={ymlFileName}
                        onChange={handleChange}
                        handleFilesDropped={handleFilesDropped}
                      />
                    )}
                  </Row>
                  <Row className="mb-3">
                    <Label
                      htmlFor="improvements"
                      className="col-md-2 col-form-label"
                    >
                      개선사항
                    </Label>
                    <Col>
                      <div
                        className={`ck ck-reset ck-editor ck-rounded-corners ${
                          focusedEditors.improvements
                            ? "editor-wrapper-focused"
                            : ""
                        }`}
                      >
                        <CKEditor
                          id="improvements"
                          editor={ClassicEditor}
                          data={version.improvements || ""}
                          onFocus={() => handleEditorFocus("improvements")}
                          onBlur={() => handleEditorBlur("improvements")}
                          onChange={(
                            _event: any,
                            editor: { getData: () => any },
                          ) => {
                            const data = editor.getData();
                            handleChange({
                              target: { name: "improvements", value: data },
                            });
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Label
                      htmlFor="newFeatures"
                      className="col-md-2 col-form-label"
                    >
                      신규기능
                    </Label>
                    <Col>
                      <div
                        className={`ck ck-reset ck-editor ck-rounded-corners ${
                          focusedEditors.newFeatures
                            ? "editor-wrapper-focused"
                            : ""
                        }`}
                      >
                        <CKEditor
                          id="newFeatures"
                          editor={ClassicEditor}
                          data={version.newFeatures || ""}
                          onFocus={() => handleEditorFocus("newFeatures")}
                          onBlur={() => handleEditorBlur("newFeatures")}
                          onChange={(
                            _event: any,
                            editor: { getData: () => any },
                          ) => {
                            const data = editor.getData();
                            handleChange({
                              target: { name: "newFeatures", value: data },
                            });
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Label
                      htmlFor="bugsFixed"
                      className="col-md-2 col-form-label"
                    >
                      오류수정
                    </Label>
                    <Col>
                      <div
                        className={`ck ck-reset ck-editor ck-rounded-corners ${
                          focusedEditors.bugsFixed
                            ? "editor-wrapper-focused"
                            : ""
                        }`}
                      >
                        <CKEditor
                          id="bugsFixed"
                          editor={ClassicEditor}
                          data={version.bugsFixed || ""}
                          onFocus={() => handleEditorFocus("bugsFixed")}
                          onBlur={() => handleEditorBlur("bugsFixed")}
                          onChange={(
                            _event: any,
                            editor: { getData: () => any },
                          ) => {
                            const data = editor.getData();
                            handleChange({
                              target: { name: "bugsFixed", value: data },
                            });
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Checkbox
                      label="표시여부"
                      id="displayFlag"
                      checked={version.displayFlag}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.checked) {
                          setVersion({ ...version, displayFlag: 1 });
                        } else {
                          setVersion({ ...version, displayFlag: 0 });
                        }
                      }}
                      extraClass="mt-2"
                      fullWidthLabel={false}
                    />
                  </Row>

                  <Row>
                    <FormButtonsTypes
                      isDetailView={isDetailView}
                      openModal={openModal}
                      handleCancel={handleCancel}
                      hideDeleteButton={true}
                      showDownloadButton={true}
                      downloadFileName={downloadFileName}
                      handleDownload={() =>
                        handleDownload(version?.fileUrl || "")
                      }
                    />
                  </Row>

                  <ConfirmModalTypes
                    isLoading={loading}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    action={action}
                    handleSave={handleSaveVersion}
                    loadMessage="업로드 중..."
                    // handleDelete={handleDeleteUser}
                  />
                </AvForm>
              </CustomCardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </PageContainer>
    </>
  );
};

export default VersionForm;
