import { slicingStatusOptions } from "../Slicing";

type ClickableCellProps<T> = {
  value?: React.ReactNode;
  row: T;
  handleRowClick: (item: T) => void;
};

export const ClickableCell = <T,>({
  value,
  row,
  handleRowClick,
}: ClickableCellProps<T>) => {
  return (
    <span
      style={{ textDecoration: "underline", cursor: "pointer" }}
      onClick={() => handleRowClick(row)}
    >
      {value}
    </span>
  );
};

//fix any type
export const SlicingStatusCell = ({ value }: any) => {
  let color = "";
  switch (value) {
    case "receipt":
      color = "#fcb92c";
      break;
    case "progress":
      color = "#5664d2";
      break;
    case "complete":
      color = "#1cbb8c";
      break;
    case "fail":
      color = "#ff3d5f";
      break;
    default:
      color = "black";
      break;
  }

  return (
    <span style={{ color: color }}>
      {slicingStatusOptions.find((option) => option.value === value)?.label}
    </span>
  );
};
