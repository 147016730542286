import { Input } from "reactstrap";
import styled from "styled-components";

export const ListHoverImage = styled.div`
  display: none;
  position: absolute;
  top: -120px;
  left: 50%;
  width: 200px;
  height: 200px;
  transform: translate(-50%, -50%);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 100000;
  pointer-events: none;
  border: 1px solid #dadada;
  border-radius: 5px;
`;

export const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 100px;
  position: relative;

  & ${Input}.form-check-input {
    margin-right: 6px;
  }
  min-width: 100px;

  span {
    margin-top: 3px;
    padding-left: 3px;
    color: gray;
    cursor: pointer;
    position: relative;
  }

  span:hover + ${ListHoverImage} {
    display: block;
  }
`;
