import { create } from "zustand";
import * as Api from "@metamorp/api-back";

const usePrintingProfileStore = create((set) => ({
  profiles: [],
  loading: false,
  error: null,
  applications: [],
  setProfiles: (profiles) => set({ profiles }),
  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error }),
  setApplications: (applications) => set({ applications }),
  getApplicationsTypes: async () => {
    try {
      const response = await Api.Common.getApplicationList();

      set({ applications: response.data });
    } catch (error) {
      console.error("Error fetching applications:", error);
    }
  },
}));

export default usePrintingProfileStore;
