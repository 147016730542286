import { create } from "zustand";

const useAppTypesStore = create((set) => ({
  appTypes: [],
  loading: false,
  error: null,
  setAppTypes: (appTypes) => set({ appTypes }),
  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error }),
}));

export default useAppTypesStore;
