import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import * as Api from "@metamorp/api-back";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

//Fake backend
import fakeBackend from "./helpers/AuthType/fakeBackend";
import AppRoute from "./routes/route";
import useLayoutStore from "./zustandStore/layoutStore";
import useStore from "./zustandStore";
import { isProd } from "./utils/isProd";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { isTokenValid } from "./utils/isTokenValid";

// Activating fake backend
fakeBackend();

const ProtectedRoute = ({ children, errorMessage }) => {
  const { token, logout, isTokenExpired } = useStore();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token || isTokenExpired()) {
      const message =
        errorMessage.trim() || "Your session has expired. Please log in again.";
      // set id to toast and toastContainer to prevent duplication
      toast.error(message, {
        autoClose: 3000,
        toastId: "app-toast",
      });
      logout();
      navigate("/login");
    } else {
      setIsAuthenticated(true);
    }
  }, [token, errorMessage, logout, navigate, isTokenExpired]);

  if (!isAuthenticated) {
    return <div>Loading...</div>;
  } else {
    return children;
  }
};

const App = () => {
  const { token, setToken, logout } = useStore();
  const layoutType = useLayoutStore((state) => state.layoutType);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  const getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  const Layout = getLayout();

  useLayoutEffect(() => {
    Api.environment.setEnvironment(isProd);
  }, []);

  // check if token and navigation works fine without delay after releasing to stg
  // check if this code is needed after releasing to stg
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const expireTime = localStorage.getItem("expireTime");
    if (accessToken && expireTime) {
      setToken(accessToken, parseInt(expireTime, 10));
      Api.environment.setAccessToken(accessToken);
    } else {
      logout();
    }
  }, [logout, setToken]);

  useEffect(() => {
    if (token) {
      Api.environment.setAccessToken(token);
    }
  }, [token]);

  useEffect(() => {
    Api.onError((res) => {
      if (res.code === 401) {
        setErrorMessage(res.message.ko);
        setTimeout(() => {
          logout();
          navigate("/login");
        }, 3000);
      }
    });
  }, [logout, navigate]);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const expireTime = localStorage.getItem("expireTime");
    if (accessToken && isTokenValid(accessToken)) {
      setToken(accessToken, expireTime ? parseInt(expireTime, 10) : undefined);
      Api.environment.setAccessToken(accessToken);
    } else {
      logout();
    }
  }, [setToken, logout]);

  return (
    <Fragment>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
            exact={true}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <ProtectedRoute errorMessage={errorMessage}>
                <AppRoute>
                  <Layout>{route.component}</Layout>
                </AppRoute>
                <ToastContainer containerId="app-toast" />
              </ProtectedRoute>
            }
            key={idx}
            exact={true}
          />
        ))}
      </Routes>
    </Fragment>
  );
};

export default App;
