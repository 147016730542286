import { create } from "zustand";
import * as Api from "@metamorp/api-back";

type SlicingRequestState = {
  slicingRequests: Api.Response.SlicingItem[];
  loading: boolean;
  error: string | null;
  setSlicingRequests: (slicingRequests: Api.Response.SlicingItem[]) => void;
  setLoading: (loading: boolean) => void;
  setError: (error: string | null) => void;
};

const useSlicingStore = create<SlicingRequestState>((set) => ({
  slicingRequests: [],
  loading: false,
  error: null,
  setSlicingRequests: (slicingRequests) => set({ slicingRequests }),
  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error }),
}));

export default useSlicingStore;
