import { create } from "zustand";

const usePrinterStore = create((set) => ({
  printers: [],
  loading: false,
  error: null,
  setPrinters: (printers) => set({ printers }),
  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error }),
}));

export default usePrinterStore;
