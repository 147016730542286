import React from "react";
import { Col, Label } from "reactstrap";
import { CustomSelectContainer } from "../../pages/Users/styled";

const Selector = ({
  id,
  options,
  value,
  onChange,
  label,
  isRequired = false,
  colMd = 10,
}) => {
  return (
    <>
      <Label className="col-md-2 col-form-label" htmlFor={id}>
        {label}
        {isRequired && (
          <>
            (<span className="text-danger">*</span>)
          </>
        )}
      </Label>
      <Col md={colMd}>
        <CustomSelectContainer>
          <select
            className="form-select"
            id={id}
            name={id}
            value={value}
            onChange={onChange}
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </CustomSelectContainer>
      </Col>
    </>
  );
};

export default Selector;
