import React, { useState, useEffect } from "react";

const CountdownTimer = ({ expireTimeStr }) => {
  const [timeRemaining, setTimeRemaining] = useState("");

  useEffect(() => {
    const expireTime = new Date(expireTimeStr).getTime();
    const updateTimer = () => {
      const now = new Date().getTime();
      const timeDiff = expireTime - now;

      if (timeDiff <= 0) {
        setTimeRemaining("0:00");
        clearInterval(timerId);
      } else {
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
        setTimeRemaining(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);
      }
    };
    updateTimer();
    const timerId = setInterval(updateTimer, 1000);
    return () => clearInterval(timerId);
  }, [expireTimeStr]);

  return <p className="fw-bold">{timeRemaining} remain</p>;
};

export default CountdownTimer;
