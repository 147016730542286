import React from "react";
import PrinterForm from "./PrinterForm";

const PrinterDetails = () => {
  return (
    <div>
      <PrinterForm isDetailView />
    </div>
  );
};

export default PrinterDetails;
