import { CardImg } from "reactstrap";
import styled from "styled-components";

export const CardImgContainer = styled(CardImg)`
  border: 1px solid #dadada;
  position: relative;
  object-fit: cover;
  cursor: pointer;
`;

export const HoverImage = styled.div`
  display: none;
  position: fixed;
  top: 50%;
  left: 55%;
  width: 100%;
  height: 100%;
  max-width: 20rem;
  max-height: 20rem;
  transform: translate(-50%, -50%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10;
  pointer-events: none;
  background-color: #ffffff;
  border: 1px solid #dadada;
  border-radius: 3px;
`;

export const RowContainer = styled.div`
  &:hover ${HoverImage} {
    display: block;
  }
`;

export const ListHoverImage = styled.div`
  display: none;
  position: fixed;
  top: 50%;
  left: 55%;
  width: 50%;
  height: 50%;
  max-width: 20rem;
  max-height: 20rem;
  transform: translate(-50%, -50%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  pointer-events: none;
  background-color: #ffffff;
  border: 1px solid #dadada;
  border-radius: 3px;
  z-index: 1000;
`;

export const ImgContainer = styled.div`
  position: relative;

  &:hover ${ListHoverImage} {
    display: block;
  }

  img {
    width: 50px;
    height: 50px;
    border: none;
    position: relative;
    object-fit: cover;
    cursor: pointer;
  }
`;
