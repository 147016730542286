export const MENU_ITEMS = [
  {
    id: "dashboard",
    icon: "ri-dashboard-line",
    label: "Dashboard",
    link: "/dashboard",
  },
  {
    id: "users",
    icon: "ri-user-line",
    label: "Members",
    link: "/users",
  },
  {
    id: "material",
    icon: "ri-loader-fill",
    label: "Material Management",
    link: "/material",
  },
  {
    id: "printer",
    icon: "ri-printer-line",
    label: "Printer Management",
    link: "/printer",
  },
  {
    id: "printing-profile",
    icon: "ri-printer-cloud-line",
    label: "Printing Profile",
    link: "/printing-profile",
  },
  {
    id: "support-profile",
    icon: "ri-upload-line",
    label: "Support Profile",
    link: "/support-profile",
  },
  {
    id: "app-type",
    icon: "ri-water-flash-line",
    label: "App Type Management",
    link: "/app-type",
  },
  {
    id: "version",
    icon: "ri-download-cloud-2-line",
    label: "Version Management",
    link: "/app-version",
  },
  {
    id: "slicing",
    icon: "ri-stack-line",
    label: "Slicing",
    link: "/#",
    subItems: [
      { id: "customer", label: "Customer Management", link: "/customer" },
      {
        id: "app-equipment",
        label: "App Management",
        link: "/app-equipment",
      },
      {
        id: "slicing-request",
        label: "Slicing Request",
        link: "/slicing-request",
      },
      {
        id: "project",
        label: "Project",
        link: "/project",
      },
      {
        id: "edit-request",
        label: "Edit Request",
        link: "/edit-request",
      },
    ],
  },
  {
    id: "system",
    icon: "ri-settings-4-line",
    label: "System Management",
    link: "/#",
    subItems: [
      {
        id: "account",
        label: "Account Management",
        link: "/account",
      },
    ],
  },
];
