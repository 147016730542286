import { create } from "zustand";

const useMaterialStore = create((set) => ({
  materials: [],
  loading: false,
  error: null,
  setMaterials: (materials) => set({ materials }),
  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error }),
}));

export default useMaterialStore;
