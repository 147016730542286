import styled from "styled-components";

export const TableData = styled.td`
  border-top: 1px solid #f1f5f7 !important;
  cursor: pointer;
  display: flex;
  justify-content: center;

  &:hover {
    opacity: 0.8;
  }
`;
