import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PageContainer from "../../components/Common/PageContainer";
import { Card, CardBody, Col, Input, Label, Row } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { ToastContainer, toast } from "react-toastify";
import * as Api from "@metamorp/api-back";

import NavField from "../../components/Profile/NavField";
import useSupportProfileStore from "../../zustandStore/supportProfileStore";
import RenderOptions from "../../components/Profile/classificationOptions";
import ConfirmModal from "../../components/Common/Modal";
import FormInput from "../../components/Profile/FormInput";
import Checkbox from "../../components/Profile/Checkbox";
import { PARAMS } from "./Utils/params";
import CustomInputField from "../../components/Profile/CustomInput";
import FormButtons from "../../components/Common/FormButtons";
import useStore from "../../zustandStore";
import { validateSupport } from "./Utils/validate";
import { useLocation } from "react-router-dom";
import { useLocalizedMessage } from "../../helpers/hooks";
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
} from "../../helpers/constants";

const defaultSupport = {
  profileName: "",
  displayFlag: 1, // int

  bumpHeight: null, // num
  pinDepth: null, // num
  pinTopDiameter: null, // num
  pinBottomDiameter: null, // num
  pinLength: null, // num,
  pinJointDiameter: null, // num
  useVerticalPin: true,

  voxelSize: null, // num
  infillDistance: null, // num
  overhangAngle: null, // num
  borderOffset: null, // num
  lineDistance: null, // num
  minimumHeight: null, // num

  useBrace: true,
  useBase: true,
  columnTopDiameter: null, // num
  columnBottomDiameter: null, // num
  braceDiameter: null, // num
  baseHeight: null, // num
  baseDiameter: null, // num

  useRaft: true,
  raftHeight: null, // num
  raftOffset: null, // num
  raftHoleDistance: null, // num
  raftHoleDiameter: null, // num

  applicationsIdx: 1, // int
  type: 0, // int
  part: 0, // int
  size: 0, // int
  palatal: 0, // int
  bottom: 0, // int
};

const SupportProfileForm = ({ isDetailView }) => {
  const navigate = useNavigate();
  const { supportIdx } = useParams();
  const location = useLocation();
  const { token } = useStore();
  const { applications, getApplicationsTypes, loading, setLoading } =
    useSupportProfileStore();
  const getLocalizedMessage = useLocalizedMessage();

  const breadcrumbItems = [
    { title: "Support Profile", link: "#" },
    { title: `${isDetailView ? "Edit" : "Add"}`, link: "#" },
  ];
  const [support, setSupport] = useState(defaultSupport);
  const [selectedApplication, setSelectedApplication] = useState({
    applicationsIdx: support.applicationsIdx,
    imgPath:
      applications.find(
        (app) => app.applicationsIdx === support.applicationsIdx,
      )?.imgPath || "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState("");
  const [validationErrors, setValidationErrors] = useState({});

  const getSupportByIdx = useCallback(async () => {
    const response = await Api.SupportProfile.get(supportIdx);
    const existingSupport = response.data;

    if (existingSupport) {
      setSupport(existingSupport);
      setSelectedApplication({
        applicationsIdx: existingSupport.applicationsIdx,
        imgPath: existingSupport.applicationsImgPath,
      });
    } else {
      setSupport(defaultSupport);
    }
  }, [supportIdx]);

  useEffect(() => {
    if (token) {
      Api.environment.setAccessToken(token);
      getApplicationsTypes();
    }
  }, [getApplicationsTypes, token]);

  useEffect(() => {
    if (isDetailView) {
      getSupportByIdx();
    }
  }, [getSupportByIdx, isDetailView]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    let newValue;

    if (type === "checkbox") {
      newValue = checked;
    } else if (
      value === null ||
      value === "" ||
      (!value?.length && isNaN(value))
    ) {
      newValue = null;
    } else if (type === "number") {
      newValue = parseFloat(value);
    } else {
      newValue = value;
    }

    setSupport((prevSupport) => ({ ...prevSupport, [name]: newValue }));
  };

  const handleApplicationTypeChange = (e) => {
    const selectedIdx = parseInt(e.target.value, 10);
    const selectedApp = applications.find(
      (app) => app.applicationsIdx === selectedIdx,
    );

    setSelectedApplication({
      applicationsIdx: selectedIdx,
      imgPath: selectedApp.imgPath,
    });
    setSupport((prevProfile) => ({
      ...prevProfile,
      applicationsIdx: selectedIdx,
    }));
  };

  const openModal = (actionType) => {
    setAction(actionType);
    setIsOpen(true);
  };

  const handleValidationError = (id, errorMessage) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [id]: errorMessage,
    }));
  };

  const handleSaveSupport = async (e) => {
    if (e) e.preventDefault();

    const { isValid } = validateSupport({ support });

    if (!isValid) {
      toast.error("(*) 있는 모든 필드 입력해주세요", {
        autoClose: 3000,
      });
      return;
    }

    if (Object.values(validationErrors).some((error) => error)) {
      toast.error("저장하시기 전에 양식의 오류를 수정해 주세요", {
        autoClose: 3000,
      });
      return;
    }

    setLoading(true);
    const method = isDetailView ? "PUT" : "POST";

    try {
      const updatedSupport = {
        profileName: support.profileName,
        displayFlag: support.displayFlag,
        bumpHeight: support.bumpHeight,
        pinDepth: support.pinDepth,
        pinTopDiameter: support.pinTopDiameter,
        pinBottomDiameter: support.pinBottomDiameter,
        pinLength: support.pinLength,
        pinJointDiameter: support.pinJointDiameter,
        useVerticalPin: support.useVerticalPin,

        voxelSize: support.voxelSize,
        infillDistance: support.infillDistance,
        overhangAngle: support.overhangAngle,
        borderOffset: support.borderOffset,
        lineDistance: support.lineDistance,
        minimumHeight: support.minimumHeight,

        useBrace: support.useBrace,
        useBase: support.useBase,
        columnTopDiameter: support.columnTopDiameter,
        columnBottomDiameter: support.columnBottomDiameter,
        braceDiameter: support.braceDiameter ?? 0,
        baseHeight: support.baseHeight ?? 0,
        baseDiameter: support.baseDiameter ?? 0,

        useRaft: support.useRaft,
        raftHeight: support.raftHeight ?? 0,
        raftOffset: support.raftOffset ?? 0,
        raftHoleDistance: support.raftHoleDistance ?? 0,
        raftHoleDiameter: support.raftHoleDiameter ?? 0,

        applicationsIdx: support.applicationsIdx,
        type: support.type ?? 0,
        part: support.part ?? 0,
        size: support.size ?? 0,
        palatal: support.palatal ?? 0,
        bottom: support.bottom ?? 0,
      };

      let response;
      if (method === "PUT") {
        response = await Api.SupportProfile.update({
          ...updatedSupport,
          supportIdx: parseFloat(supportIdx),
        });
      } else if (method === "POST") {
        response = await Api.SupportProfile.add(updatedSupport);
      }

      const successMessage = getLocalizedMessage(
        response,
        DEFAULT_SUCCESS_MESSAGE,
      );
      toast.success(successMessage, {
        autoClose: 3000,
      });
      setTimeout(() => {
        if (isDetailView) {
          navigate(`/support-profile${location.search}`);
        } else {
          navigate("/support-profile");
        }
      }, 1000);
    } catch (error) {
      console.error("Error: adding new profile", error);
      const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteSupport = async () => {
    setLoading(true);

    try {
      const response = await Api.SupportProfile.delete(supportIdx);
      const successMessage = getLocalizedMessage(
        response,
        DEFAULT_SUCCESS_MESSAGE,
      );
      toast.success(successMessage, { autoClose: 3000 });
      setTimeout(() => {
        navigate("/support-profile");
      }, 1000);
    } catch (error) {
      console.error("Error deleting support:", error);
      const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCopySupport = async () => {
    setLoading(true);

    try {
      const response = await Api.SupportProfile.copy(supportIdx);
      const successMessage = getLocalizedMessage(
        response,
        DEFAULT_SUCCESS_MESSAGE,
      );
      toast.success(successMessage, { autoClose: 3000 });
      setTimeout(() => {
        navigate("/support-profile");
      }, 1000);
    } catch (error) {
      const errMessage = getLocalizedMessage(error, DEFAULT_ERROR_MESSAGE);
      toast.error(errMessage, {
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(`/support-profile${location.search}`);
  };

  useEffect(() => {
    if (!support.useBase) {
      setSupport((prevSupport) => ({
        ...prevSupport,
        baseHeight: null,
        baseDiameter: null,
      }));
    }
  }, [support.useBase]);

  useEffect(() => {
    if (!support.useBrace) {
      setSupport((prevSupport) => ({
        ...prevSupport,
        braceDiameter: null,
      }));
    }
  }, [support.useBrace]);

  useEffect(() => {
    if (!support.useRaft) {
      setSupport((prevSupport) => ({
        ...prevSupport,
        raftHeight: null,
        raftOffset: null,
        raftHoleDistance: null,
        raftHoleDiameter: null,
      }));
    }
  }, [support.useRaft]);

  return (
    <>
      <PageContainer breadcrumbItems={breadcrumbItems} title="Support Profile">
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <AvForm>
                  <NavField title="기본설정">
                    <FormButtons
                      isDetailView={isDetailView}
                      openModal={openModal}
                      handleCancel={handleCancel}
                      showCopyButton={true}
                    />
                  </NavField>
                  <Row>
                    <Col>
                      <FormInput
                        label="프로파일명"
                        id="profileName"
                        type="text"
                        placeholder="프로파일명을 입력해주세요"
                        value={support.profileName}
                        onChange={handleChange}
                        errorMessage="프로파일명을 입력해주세요"
                        isRequired
                        validate={{ required: { value: true } }}
                      />
                    </Col>
                    {!isDetailView && (
                      <Col>
                        <Checkbox
                          label="표시여부"
                          id="displayFlag"
                          checked={support.displayFlag === 1}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSupport({ ...support, displayFlag: 1 });
                            } else {
                              setSupport({ ...support, displayFlag: 0 });
                            }
                          }}
                        />
                      </Col>
                    )}
                  </Row>
                  {isDetailView && (
                    <Row>
                      <Col>
                        <Checkbox
                          label="표시여부"
                          id="displayFlag"
                          checked={support.displayFlag === 1}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSupport({ ...support, displayFlag: 1 });
                            } else {
                              setSupport({ ...support, displayFlag: 0 });
                            }
                          }}
                        />
                      </Col>
                      <Col>
                        <Checkbox
                          label="기본프로파일여부"
                          id="defaultFlag"
                          checked={support.defaultFlag === 1}
                          disabled={true}
                          style={{ opacity: "0.5" }}
                          readOnly
                        />
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col className="col-12 col-md-6">
                      <NavField title="Hard" />
                      {PARAMS[0].params.map((hard) => {
                        const min =
                          typeof hard.min === "function"
                            ? hard.min(support)
                            : hard.min;
                        const max =
                          typeof hard.max === "function"
                            ? hard.max(support)
                            : hard.max;

                        return hard.type === "number" ? (
                          <div key={hard.name}>
                            <CustomInputField
                              min={min}
                              max={max}
                              step={hard.decimal}
                              label={hard.label}
                              unit={hard.unit}
                              id={hard.name}
                              type={hard.type}
                              value={support[hard.name]}
                              onChange={handleChange}
                              placeholder={hard.placeholder}
                              onValidationError={handleValidationError}
                              isRequired
                            />
                          </div>
                        ) : (
                          <div key={hard.name}>
                            <Checkbox
                              label={hard.label}
                              id={hard.name}
                              checked={support[hard.name]}
                              onChange={(e) =>
                                setSupport({
                                  ...support,
                                  [hard.name]: e.target.checked,
                                })
                              }
                            />
                          </div>
                        );
                      })}
                    </Col>
                    <Col className="col-12 col-md-6">
                      <NavField title="Supporting Point" />
                      {PARAMS[1].params.map((point) => (
                        <div key={point.name}>
                          <CustomInputField
                            min={point.min}
                            max={point.max}
                            step={point.decimal}
                            label={point.label}
                            unit={point.unit}
                            id={point.name}
                            type={point.type}
                            value={support[point.name]}
                            onChange={handleChange}
                            placeholder={point.placeholder}
                            onValidationError={handleValidationError}
                            isRequired
                          />
                        </div>
                      ))}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-12 col-md-6">
                      <NavField title="Body" />
                      {PARAMS[2].params.map((body) => {
                        const min =
                          typeof body.min === "function"
                            ? body.min(support)
                            : body.min;
                        const max =
                          typeof body.max === "function"
                            ? body.max(support)
                            : body.max;

                        const isDisabled =
                          (body.name === "braceDiameter" &&
                            !support.useBrace) ||
                          (body.name === "baseHeight" && !support.useBase) ||
                          (body.name === "baseDiameter" && !support.useBase);

                        return body.type === "number" ? (
                          <div key={body.name}>
                            <CustomInputField
                              min={min}
                              max={max}
                              step={body.decimal}
                              label={body.label}
                              unit={body.unit}
                              id={body.name}
                              type={body.type}
                              value={support[body.name]}
                              onChange={handleChange}
                              placeholder={body.placeholder}
                              onValidationError={handleValidationError}
                              isRequired={!isDisabled}
                              disabled={isDisabled}
                            />
                          </div>
                        ) : (
                          <Col className="mb-3" key={body.name}>
                            <Checkbox
                              label={body.label}
                              id={body.name}
                              checked={support[body.name]}
                              onChange={(e) =>
                                setSupport({
                                  ...support,
                                  [body.name]: e.target.checked,
                                })
                              }
                            />
                          </Col>
                        );
                      })}
                    </Col>

                    <Col className="col-12 col-md-6">
                      <NavField title="Raft" />
                      {PARAMS[3].params.map((raft) => {
                        const min =
                          typeof raft.min === "function"
                            ? raft.min(support)
                            : raft.min;
                        const max =
                          typeof raft.max === "function"
                            ? raft.max(support)
                            : raft.max;

                        return raft.type === "boolean" ? (
                          <Col className="mb-4" key={raft.name}>
                            <Checkbox
                              label={raft.label}
                              id={raft.name}
                              checked={support[raft.name]}
                              onChange={(e) =>
                                setSupport({
                                  ...support,
                                  [raft.name]: e.target.checked,
                                })
                              }
                            />
                          </Col>
                        ) : (
                          <div key={raft.name}>
                            <CustomInputField
                              min={min}
                              max={max}
                              step={raft.decimal}
                              label={raft.label}
                              unit={raft.unit}
                              id={raft.name}
                              type={raft.type}
                              value={support[raft.name]}
                              onChange={handleChange}
                              placeholder={raft.placeholder}
                              onValidationError={handleValidationError}
                              isRequired={!support.useRaft ? false : true}
                              disabled={!support.useRaft}
                            />
                          </div>
                        );
                      })}
                    </Col>
                  </Row>
                  <NavField title="Classification" />
                  <Row
                    className="mb-3"
                    style={{ dispaly: "flex", gap: "70px" }}
                  >
                    <Col xl="2">
                      <img
                        src={selectedApplication.imgPath}
                        alt={`application-${support.applicationsIdx}`}
                        className="rounded avatar-lg"
                        style={{ objectFit: "contain" }}
                      />
                    </Col>
                    <Col xl="2" className="mb-4">
                      <Label className="form-label h6">Applications</Label>
                      {applications.map((type) => (
                        <div
                          className="form-check mb-2"
                          key={type.applicationsIdx}
                        >
                          <Input
                            type="radio"
                            id={`application-${type.applicationsIdx}`}
                            name="applicationsIdx"
                            className="form-check-input"
                            value={type.applicationsIdx}
                            checked={
                              selectedApplication.applicationsIdx ===
                              type.applicationsIdx
                            }
                            onChange={handleApplicationTypeChange}
                          />
                          <Label
                            htmlFor={`application-${type.applicationsIdx}`}
                            className="form-check-label"
                          >
                            {type.name}
                          </Label>
                        </div>
                      ))}
                    </Col>
                    <Col>
                      <RenderOptions
                        profile={support}
                        selectedApplication={selectedApplication}
                        handleChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <FormButtons
                    isDetailView={isDetailView}
                    openModal={openModal}
                    handleCancel={handleCancel}
                    showCopyButton={true}
                  />
                  <ConfirmModal
                    isLoading={loading}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    action={action}
                    handleSave={handleSaveSupport}
                    handleDelete={handleDeleteSupport}
                    handleCopy={handleCopySupport}
                  />
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </PageContainer>
    </>
  );
};

export default SupportProfileForm;
