import React from "react";
import { Container } from "reactstrap";

import Breadcrumbs from "./Breadcrumb";

const PageContainer = ({ children, breadcrumbItems, title }) => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={title ? title : "New Page"}
            breadcrumbItems={breadcrumbItems}
          />
        </Container>
        {children}
      </div>
    </React.Fragment>
  );
};

export default PageContainer;
