import React, { useRef } from "react";
import {
  CustomButton,
  CustomFileInputContainer,
  FileNameDisplay,
  HiddenFileInput,
} from "./styled";
import { Col } from "reactstrap";

const INPUT_FILE_PLACEHOLDER = "선택된 파일 없음 (권장 사이즈: 240x240)";

const CustomFileInput = ({
  name,
  fileName,
  onChange,
  allowMultiple = false,
}) => {
  const fileInputRef = useRef(null);

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Col className="d-flex align-items-center">
      <CustomFileInputContainer
        className="input-group cursor-pointer"
        onClick={handleClick}
      >
        <HiddenFileInput
          ref={fileInputRef}
          name={name ?? "file"}
          multiple={allowMultiple}
          onChange={onChange}
        />
        <CustomButton>파일 선택</CustomButton>
        <FileNameDisplay>{fileName || INPUT_FILE_PLACEHOLDER}</FileNameDisplay>
      </CustomFileInputContainer>
    </Col>
  );
};

export default CustomFileInput;
