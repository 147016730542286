import React, { useState } from "react";
import { AvField } from "availity-reactstrap-validation";
import { Col, Label } from "reactstrap";

const FormInput = ({
  label,
  placeholder,
  min,
  id,
  type,
  value,
  onChange,
  onValidationError = () => {},
  validate,
  isRequired = false,
  fullWidthLabel = true,
}) => {
  const [errorMessage, setErrorMessage] = useState("");

  const validateInput = (inputValue) => {
    let numericValue;
    if (type === "number") {
      numericValue = parseFloat(inputValue);
    } else {
      numericValue = inputValue;
    }
    if (min !== undefined && numericValue < min) {
      const error = `Minimum value is ${min}`;
      setErrorMessage(error);
      onValidationError(id, error);
      return false;
    }

    setErrorMessage("");
    onValidationError(id, "");
    return true;
  };

  const handleChange = (e) => {
    const newValue = e.target.value;

    validateInput(newValue);

    onChange({
      target: {
        name: id,
        value: type === "number" ? parseFloat(newValue) || "" : newValue,
      },
    });
  };

  return (
    <>
      <Label
        htmlFor={id}
        className={`col-md-2 col-form-label ${fullWidthLabel ? "w-100" : ""}`}
      >
        {label}
        {isRequired && (
          <>
            (<span className="text-danger">*</span>)
          </>
        )}
      </Label>
      <Col>
        <AvField
          min={min}
          data-type={type}
          type={type}
          placeholder={placeholder}
          id={id}
          name={id}
          value={value || ""}
          onChange={handleChange}
          errorMessage={errorMessage}
          validate={validate}
        />
      </Col>
    </>
  );
};

export default FormInput;
