import { Modal, Tooltip } from "reactstrap";
import styled from "styled-components";

export const CustomModal = styled(Modal)`
  min-width: ${(props) => props.size === "md" && "37.5rem"};

  .modal-header {
    justify-content: center;
    border-bottom: none;
    padding-bottom: 0.625rem;
    position: relative;

    .btn-close {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }

    .modal-title {
      font-size: 1.5rem;
    }
  }

  .modal-body {
    display: flex;
    justify-content: center;
    padding-top: 0;
    p {
      margin-bottom: 0;
      font-size: 1rem;
      color: #626466;
    }

    .table-responsive {
      min-width: ${(props) => props.size === "md" && "31.25rem"};
    }
  }

  .modal-footer {
    border-top: none;
    justify-content: center;
    padding-top: 1.25rem;
  }
`;

export const CustomFileInputContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  border: 0.0625rem solid #dadada;
  border-radius: 0.3125rem;

  :focus {
    color: var(--bs-body-color);
    background-color: var(--bs-secondary-bg);
    border-color: #abb2e9;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(86, 100, 210, 0.25);
  }
`;

export const HiddenFileInput = styled.input.attrs({ type: "file" })`
  display: none;
  position: relative;
`;

export const CustomButton = styled.button`
  position: absolute;
  top: 0;
  background-color: #f8f9fa;
  padding: 0.5rem;
  border: none;
  border-right: 0.0625rem solid #dadada;
  border-bottom: 0.0625rem solid #dadada;
  border-radius: 0.25rem 0 0 0.25rem !important;
  margin-right: 0.625rem;
  margin-left: 0.0313rem !important;
  flex: 1;
`;

export const FileNameDisplay = styled.div`
  margin-left: 5rem !important;
  color: #6c757d;
  flex: 1;
  min-height: 2.375rem;
  display: flex;
  align-items: center;
  justify-content: start;
  white-space: wrap;
`;

export const CustomTooltip = styled(Tooltip)`
  margin-bottom: 10px !important;

  .tooltip-inner {
    background-color: rgba(222, 226, 230, 0.9) !important;
    color: black !important;
  }

  .bs-tooltip-top .tooltip-arrow::before,
  .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
    border-top-color: rgba(222, 226, 230, 0.9) !important;
  }
`;
