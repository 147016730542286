import React, { useRef } from "react";
import {
  CustomButton,
  CustomFileInputContainer,
  FileNameDisplay,
  HiddenFileInput,
} from "./styled";
import { Col } from "reactstrap";

const INPUT_FILE_PLACEHOLDER = "선택된 파일 없음";

type DraggableFileInputProps = {
  name: string;
  fileName: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  allowMultiple?: boolean;
  handleFilesDropped?: (files: FileList) => void;
};

const DraggableFileInput = ({
  name,
  fileName,
  onChange,
  allowMultiple = false,
  handleFilesDropped = () => {},
}: DraggableFileInputProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    const files = e.dataTransfer.files;
    if (files.length) {
      handleFilesDropped(files);
    }
  };

  return (
    <Col md={10} className="d-flex align-items-center">
      <CustomFileInputContainer
        className="input-group cursor-pointer"
        onClick={handleClick}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <HiddenFileInput
          ref={fileInputRef}
          name={name ?? "file"}
          multiple={allowMultiple}
          onChange={onChange}
        />
        <CustomButton>파일 선택</CustomButton>
        <FileNameDisplay>{fileName || INPUT_FILE_PLACEHOLDER}</FileNameDisplay>
      </CustomFileInputContainer>
    </Col>
  );
};

export default DraggableFileInput;
