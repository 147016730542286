import CustomerForm from "./CustomerForm";

const CustomerDetails = () => {
  return (
    <div>
      <CustomerForm isDetailView />
    </div>
  );
};

export default CustomerDetails;
